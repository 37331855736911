import axios from "axios";
import authHeader from "./auth-header";

const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/events', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getListByDate = async () => {
    const today = new Date();
    const nyear= today.getFullYear();
    const syear = '' + nyear;
    const nmonth = today.getMonth() + 1;
    const smonth = (nmonth < 10 ? '0' : '') + nmonth
    const nday = today.getDate();
    const sday = (nday < 10 ? '0' : '') + nday
    const todayAsString = `${syear}-${smonth}-${sday}`;
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/events/date/${todayAsString}`, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const event = {
    getListByDate,
    save,
};

export default event;