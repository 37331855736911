
const getInstanceName = () => {
    if (process.env.REACT_APP_SERVER_URL.includes('localhost')) {
        return 'localhost';
    }
    return process.env.REACT_APP_SERVER_URL.substring(process.env.REACT_APP_SERVER_URL.indexOf('://')+3, process.env.REACT_APP_SERVER_URL.indexOf('-racing')).toUpperCase();
}

// default export ---------------------------------------------------
const instance = {
    getInstanceName
};

export default instance;