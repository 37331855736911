import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Radio, Table, Tooltip} from "antd";
import tracks from "../services/track.service";
import {DeleteOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {confirm} = Modal;

export const TrackDataList = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [loading, set_loading] = useState(false);
    const [form] = Form.useForm();
    const [data, set_data] = useState([]);
    const [selectionType, setSelectionType] = useState("radio");
    const [selectedRow, setSelectedRow] = useState();
    const [filterTable, setfilterTable] = useState(null);
    const {selected} = useParams();

    // columns definition ---------------------------------------------

    const columns = [
        {
            title: t('Track.Code'),
            dataIndex: "code",
            key: "code",
        },
        {
            title: t('Track.Discipline'),
            dataIndex: "type",
            key: "type",
        },

        {
            title: t('Track.Site'),
            dataIndex: "venueLabel",
            key: "venueLabel",
            render: (value, record) => (<>{value} ({record.venueCode})</>),
        },
        {
            title: t('Track.Distance'),
            dataIndex: "distance",
            key: "distance",
            render: (value, record) => (<>{value}m</>),
            align: "right",
        },
        {
            title: t('Track.status'),
            dataIndex: "status",
            key: "status",
            render: (value, record) => value,
        },
        {
            title: t('Track.Action_Form'),
            dataIndex: "action",
            key: "action",
            className: "col_action",
            render: (value, record) => {
                return (
                    <>
                        <Tooltip title={t('Track.Delete')}>
                            <Button
                                onClick={(e) => del(e, record.uuid)}
                                shape="circle"
                                icon={<DeleteOutlined/>}
                            />
                        </Tooltip>
                    </>
                );
            },
        }

    ];

    // list -----------------------------------------------------------
    const del = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: t('Track.ConfirmDelete'),
            content: t('Track.DeleteDefinitely'),
            onOk() {
                return new Promise(async (resolve) => {
                    await tracks.del(uuid);
                    await list();
                    resolve();
                });
            },
            onCancel() {
            },
        });
    };


    // load_data ------------------------------------------------------
    const list = async () => {
        tracks.list()
            .then((r) => {
                if (r.data) {
                    set_data(r.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            setSelectedRow(selectedRowKeys[0]);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            name: record.name,
        }),
    };

    const searchA = (value) => {
        let filterTable = data.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        setfilterTable(filterTable);
    };

    // useEffect ------------------------------------------------------

    // first load, init data
    useEffect(() => {
        set_loading(true);
        list().then(() => set_loading(false));
    }, []);

    useEffect(() => {
        if (selectedRow && data) {
            for (let i in data) {
                if (data[i].uuid === selectedRow) {
                    props.trackCallback(data[i].uuid);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, data]);

    useEffect(() => {
        if (selected) {
            setSelectedRow(Number(selected));
        }
    }, [selected]);

    // render ---------------------------------------------------------

    return (
        <div className="pdl_wrapper">
            <Form form={form} component={false}>

                <Radio.Group onChange={({target: {value}}) => { setSelectionType(value) }} value={selectionType}/>

                <Input.Search
                    placeholder={t('Track.Search')}
                    enterButton
                    onSearch={searchA}
                    pagination={{pageSize: 20}}
                    className="track_list_search"/>

                <Table
                    rowSelection={{
                        type: selectionType,
                        selectedRowKeys: [selectedRow],
                        ...rowSelection,
                    }}
                    bordered
                    columns={columns}
                    dataSource={filterTable === null ? data : filterTable}
                    onSearch={searchA}
                    rowKey="uuid"
                    loading={loading}
                    className="common_list track_list"
                    pagination={{pageSize: 20}}/>

            </Form>
        </div>
    )
})

export default TrackDataList;