import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const listAll = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/roles/', method: 'get', headers: authHeader(),
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// default export ---------------------------------------------------
const role = {
    listAll
};

export default role;