import settings from "./settings.service";

const RACING_MONITORINGS_SETTINGS_LOALSTORAGE_KEY = 'RacingColumns';

const REQUIRED = [
    {label: 'monitoring.competitorNumber', value: 'number', required: true},
    {label: 'monitoring.status', value: 'status', required: true},
    {label: 'monitoring.fusion', value: 'fusion', required: true},
];

const OPTIONALS = [
    {label: 'monitoring.trackers', value: 'trackers', required: false},
    {label: 'monitoring.isInCharge', value: 'inCharge', required: false},
    {label: 'monitoring.networkProvider', value: 'network', required: false},
    {label: 'monitoring.networkType', value: 'nwtype', required: false},
    {label: 'monitoring.battery', value: 'battery', required: false},
    {label: 'monitoring.lt', value: 'lt', required: false},
    {label: 'monitoring.mode', value: 'mode', required: false},
    {label: 'monitoring.latency', value: 'latency', required: false},
    {label: 'monitoring.json', value: 'json', required: false},
    {label: 'monitoring.rank', value: 'rank', required: false},
    {label: 'monitoring.hr', value: 'hr', required: false},
    {label: 'monitoring.cps', value: 'cps', required: false},
    {label: 'monitoring.dpdp', value: 'dpdp', required: false},
    {label: 'monitoring.lastpc', value: 'lastpc', required: false},
]

export const MONITORING_COLUMNS = [
    ...REQUIRED,
    ...OPTIONALS,
]

async function loadDefault() {
    return Promise.resolve()
        .then(() => settings.getMonitoringColumns())
        .then(list => [
            ...REQUIRED.map(column => column.value),
            ...list,
        ])
        .then(list => JSON.stringify(list))
        .then(stringified => localStorage.setItem(RACING_MONITORINGS_SETTINGS_LOALSTORAGE_KEY, stringified))
}

async function init() {
    return Promise.resolve()
        .then(() => localStorage.getItem(RACING_MONITORINGS_SETTINGS_LOALSTORAGE_KEY))
        .then(localColumns => !localColumns ? loadDefault() : null)
}

export async function getMonitoringColumns() {
    return Promise.resolve()
        .then(() => init())
        .then(() => localStorage.getItem(RACING_MONITORINGS_SETTINGS_LOALSTORAGE_KEY))
        .then(stringified => JSON.parse(stringified))
}

export async function setMonitoringSetting(key, isSelected) {
    return Promise.resolve()
        .then(() => getMonitoringColumns())
        .then(list => isSelected ? [...list, key] : list.filter(value => value !== key))
        .then(list => localStorage.setItem(RACING_MONITORINGS_SETTINGS_LOALSTORAGE_KEY, JSON.stringify(list)))
}

export async function reloadDefaultMonitoringSettings() {
    return Promise.resolve()
        .then(() => loadDefault())

}
