import React, {useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom';
import {Button, Form, Input, message, Select, Tabs} from 'antd';
import track from "../services/track.service";
import race from "../services/race.service";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function PrivateRaceForm() {
    const {t} = useTranslation();

    const {uuid} = useParams();
    const [trackList, setTrackList] = useState([]);
    const [currentTrack, setCurrentTrack] = useState();
    const [title, setTitle] = useState();
    const [file, setFile] = useState();
    const [form] = Form.useForm();
    const history = useHistory();

    // onFinish -------------------------------------------------------
    const onFinish = (values) => {
        race
            .saveOne(values)
            .then(() => {
                message.success(t('RaceCalendar.Enregistrement'));
                history.push('/calendar');
            })
            .catch(() => {
                message.error(t('RaceCalendar.Error_msg'));
            })
    }

    function handleUpload(event) {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    }

    function onFinishUpload() {
        race
            .saveFile(file)
            .then(async (res) => {
                message.success(t('RaceCalendar.Enregistrement'));
                history.push('/calendar');
            })
            .catch((res) => {
                console.log(res);
                message.error(t('RaceCalendar.InvalidFormatFile'));
            })

    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        track.list().then((r) => {
            if (r && r.data) {
                const groupedTrackList = [];
                r.data.forEach(track => {
                    let group = groupedTrackList.find(group => group.label === track.venueLabel);
                    if (group === undefined) {
                        let entry = {
                            label: track.venueLabel,
                            options: [{label: track.venueLabel + ' : ' + track.code, value: track.uuid}]
                        }
                        groupedTrackList.push(entry)
                    } else {
                        group.options.push({label: track.venueLabel + ' : ' + track.code, value: track.uuid});
                    }
                })
                setTrackList(groupedTrackList);
            }
            if (uuid) {
                race.get(uuid).then((r) => {
                    if (r && r.data) {
                        r.data['startTime'] = new Date(r.data['date']).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        });
                        r.data['currentDate'] = new Date(r.data['date']).toLocaleDateString();
                        form.setFieldsValue(r.data);
                        setCurrentTrack(r.data.track.venue.code);
                        setTitle(t('RaceCalendar.Edit_Race') + r.data['label']);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                setTitle(t('RaceCalendar.Nv_Race'));
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">{title}</span>
                </div>
            </div>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('RaceCalendar.SubtitleForm')} key="1">

                    <Form
                        {...layout}
                        form={form}
                        name="user_form"
                        className="common_form"
                        onFinish={onFinish}>
                        <Form.Item
                            name="uuid"
                            label={t('RaceCalendar.uuid')}
                            hidden={true}>
                            <Input disabled={true}/>
                        </Form.Item>

                        <Form.Item
                            name="date"
                            label={t('RaceCalendar.Date')}
                            rules={[{required: true}]}>
                            <Input type="date"/>
                        </Form.Item>

                        <Form.Item
                            name="trackUuid"
                            label={t('RaceCalendar.Venue')}
                            rules={[{required: true}]}>
                            <Select
                                options={trackList}
                                placeholder={t('RaceCalendar.Select_Site')}
                                value={currentTrack}>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="meeting"
                            label={t('RaceCalendar.Meeting')}
                            rules={[{required: false}]}>
                            <Input type="number" placeholder="Exemple 1"/>
                        </Form.Item>

                        <Form.Item
                            name="number"
                            label={t('RaceCalendar.Number')}
                            rules={[{required: true}]}>
                            <Input type="number" placeholder="Exemple 2"/>
                        </Form.Item>

                        <Form.Item
                            name="startTime"
                            label={t('RaceCalendar.Start_Time')}
                            rules={[{required: true}]}>
                            <Input type="time" placeholder="HH:MM"/>
                        </Form.Item>

                        <Form.Item
                            name="label"
                            label={t('RaceCalendar.Label')}
                            rules={[{required: false}]}>
                            <Input type="text" placeholder="Exemple  PRIX DE LA CITE MEDIEVALE"/>
                        </Form.Item>

                        <Form.Item
                            name="distance"
                            label={t('RaceCalendar.Distance')}
                            rules={[{required: false}]}>
                            <Input type="number" placeholder="Exemple 1600"/>
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label={t('RaceCalendar.Type')}
                            rules={[{required: false}]}>
                            <Input type="text" placeholder="Exemple Plat"/>
                        </Form.Item>

                        <Form.Item {...tailLayout} className="form_toolbar">
                            <Button
                                type="primary"
                                onClick={() => history.push("/calendar")}>
                                {t('RaceCalendar.Return_Form')}
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {t('RaceCalendar.Save_Form')}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab={t('RaceCalendar.SubtitleUploadForm')} key="2">
                    <Form
                        {...layout}
                        name="race_upload_form"
                        className="common_form"
                        onFinish={onFinishUpload}>
                        <Form.Item name="file" label={t('Track.File')} rules={[{required: true}]}>
                            <Input type="file" onChange={handleUpload}/>
                        </Form.Item>
                        <Form.Item {...tailLayout} className="form_toolbar">
                            <Button
                                type="primary"
                                onClick={() => history.push("/calendar")}>
                                {t('RaceCalendar.Return_Form')}
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {t('RaceCalendar.Save_Form')}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </div>
    )

}