import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const list = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/users/', method: 'get', headers: authHeader(),
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// load -------------------------------------------------------------
const load = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/users/' + uuid, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// save -------------------------------------------------------------
const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/users/', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// del --------------------------------------------------------------
const del = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/users/' + uuid, method: 'delete', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// default export ---------------------------------------------------
const user = {
    list, load, save, del
};

export default user;