import axios from "axios";
import authHeader from "./auth-header";

const getListByRace = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/race/${uuid}/competitors`, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getListByDateAndVenue = async (date, venueCode) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/competitors?date=' + date + '&venue=' + venueCode,
        method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/competitors', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}
// default export ---------------------------------------------------
const competitor = {
    getListByRace,
    getListByDateAndVenue,
    save
};

export default competitor;