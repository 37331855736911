export function downloadTableAsCsv (tableId, includeColumnsIndex) {
    // Select rows from table_id
    let separator = ';';
    let rows = document.querySelectorAll(`#${tableId} table tr`);
    console.log(rows);
    // Construct csv
    let csv = [];
    extractDataAndCreateCsvColumn('th,td', csv, rows, separator, includeColumnsIndex);
    let csv_string = csv.join('\n');
    // Download it
    let filename = 'export_'+tableId+'_' + new Date().toLocaleDateString() + '.csv';
    let link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const extractDataAndCreateCsvColumn = (selectorType, csv, rows, separator, includeColumnsIndex) => {
    for (let i = 0; i < rows.length; i++) {
        let row = [];
        let cols = rows[i].querySelectorAll(selectorType);
        for (let j = 0; j < cols.length; j++) {
            if (includeColumnsIndex.includes(j)) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                let data = cols[j].textContent.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                if (data === '-') {
                    data = data.replace('-', '');
                }
                // Push escaped string
                if (data.length > 0 && isNaN(data)) {
                    data = '"'+data+'"';
                }
                row.push(data);
            }
        }
        csv.push(row.join(separator));
    }
}