import React from "react";
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import RaceTrack from "./raceTrack";
import RaceVenue from "./RaceVenue";

const {TabPane} = Tabs;

export default function RaceTrackAndVenue() {
    const {t} = useTranslation();

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <Tabs defaultActiveKey="2">
                <TabPane tab={t('Track.Title')} key="1">
                    <RaceTrack />
                </TabPane>
                <TabPane tab={t('venue.title')} key="2">
                    <RaceVenue />
                </TabPane>
            </Tabs>

        </div>
    );
}



