import React, {useEffect, useState} from "react";
import {Button, Form, Input, message} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
// INTERNAL
import Loader from "../components/loader"
import FusionService from "../services/fusion.service";

const layout = {
    labelCol: {span: 8}, wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

const MODE_CREATE = 'ADD';
const MODE_UPDATE = 'MOD';

export default function TrackerFusionForm() {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const [form] = Form.useForm();
    const [mode, setMode] = useState();
    const history = useHistory();

    const loadFusion = async fusionId => {
        return FusionService.listAll()
            .then(list => list.find(fusion => fusion.fusionId === fusionId))
            .then(fusion => fusion ? fusion : Promise.reject())
            .then(fusion => 
                    form.setFieldsValue({
                        id: fusion.id,
                        fusionId: fusion.fusionId,
                        trackerId1: fusion.trackers[0] ? fusion.trackers[0] : '',
                        trackerId2: fusion.trackers[1] ? fusion.trackers[1] : '',
                        trackerId3: fusion.trackers[2] ? fusion.trackers[2] : '',
                        trackerId4: fusion.trackers[3] ? fusion.trackers[3] : '',
                        trackerId5: fusion.trackers[4] ? fusion.trackers[4] : '',
                })
            );
    }

    const convertFormDataToFusion = (formData) => {
        return {
            id: formData.id,
            fusionId: formData.fusionId,
            trackers: [
                formData.trackerId1,
                formData.trackerId2,
                formData.trackerId3,
                formData.trackerId4,
                formData.trackerId5,
            ].filter(Number)
        }
    }

    // handler --------------------------------------------------------
    const save = async (formData) => {
        return Promise.resolve()
            .then(() => convertFormDataToFusion(formData))
            .then(data => mode === MODE_CREATE
                ? FusionService.saveOne(data)
                : FusionService.updateOne(data))
            .then(() => {
                message.success(mode === MODE_CREATE
                    ? t('TrackerFusion.success.save-one')
                    : t('TrackerFusion.success.update-one'));
                history.push("/fusions");
            })
            .catch(error => {
                if (error?.code === 'TRACKERS_USED_SEVERAL_MERGE') {
                    const msg = (error.trackers.length === 1)
                        ? t('TrackerFusion.error.tracker_used_several_times', {capteur: error.trackers.join(',')})
                        : t('TrackerFusion.error.trackers_used_several_times', {capteurs: error.trackers.join(',')})
                    message.error(msg);
                } else if (error?.code === 'TRACKERS_USED_SEVERAL_IN_SAME_MERGE') {
                    const msg = (error.trackers.length === 1)
                        ? t('TrackerFusion.error.tracker_used_several_times_in_same_merge', {capteur: error.trackers.join(',')})
                        : t('TrackerFusion.error.trackers_used_several_times_in_same_merge', {capteurs: error.trackers.join(',')})
                    message.error(msg);
                // Cette erreur ne peut pas arriver sur l'interface
                // } else if (error?.code === 'ID_NOT_EXISTS') {
                //     const msg = t('TrackerFusion.error.id_does_not_exist', {capteur: error.trackers.join(',')});
                //     message.error(msg);
                // Cette erreur ne peut pas arriver sur l'interface
                // } else if (error?.code === 'MERGE_ID_CHANGED') {
                //     const msg = t('TrackerFusion.error.merge_cant_be_updated', {capteur: error.trackers.join(',')});
                //     message.error(msg);
                } else {
                    console.log(error)
                    message.error(t('common.error'))
                }
            })
    }

    // useEffect ------------------------------------------------------

    useEffect(() => {
        if (id) {
            setMode(MODE_UPDATE);
            loadFusion(Number(id))
                .catch(() => message.error(t('common.error')))
                .finally(() => setLoading(false));

        } else {
            setMode(MODE_CREATE);
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // render ---------------------------------------------------------

    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {(mode === MODE_CREATE) ? t('TrackerFusion.addFusionTitle') : t('TrackerFusion.editFusionTitle') + id}
                    </span>
                </div>
            </div>
            {loading ? <Loader/> : <Form
                {...layout}
                form={form}
                className="common_form"
                onFinish={save}>
                <Form.Item name="id" label={t('TrackerFusion.id')} hidden={true}>
                    <Input disabled={true}/>
                </Form.Item>

                <Form.Item name="fusionId" label={t('TrackerFusion.fusionId')} >
                    <Input disabled={mode === MODE_UPDATE} type="text" />
                </Form.Item>

                <Form.Item name="trackerId1" label={t('TrackerFusion.trackerId1')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId2" label={t('TrackerFusion.trackerId2')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId3" label={t('TrackerFusion.trackerId3')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId4" label={t('TrackerFusion.trackerId4')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId5" label={t('TrackerFusion.trackerId5')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="cancel" onClick={() => history.push("/fusions")}>
                        {t('common.back')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('common.save')}
                    </Button>
                </Form.Item>
            </Form>}
        </div>
    )
}