import axios from "axios";
import authHeader from "./auth-header";

// load -------------------------------------------------------------
const getAll = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/',
        method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getSelected = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/selected',
        method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
        throw error;
    })
}

const getAllMccMnc = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/mcc-mnc', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getHpv2Settings = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/current/settings', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// update -------------------------------------------------------------
const update = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/', method: 'put', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// update Settings -------------------------------------------------------------
const updateHpv2Settings = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/position-server/settings', method: 'put', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// default export ---------------------------------------------------
const positionServer = {
    getAll,
    getSelected,
    update,
    updateHpv2Settings,
    getAllMccMnc,
    getHpv2Settings
};

export default positionServer;