import React from "react";
import {Trans} from "react-i18next";

export default function TrackerFusionImportInformation() {
    return (
        <div style={{fontSize: 20, color: '#cc0000', paddingBottom: 15}}>
            <Trans
                i18nKey={'TrackerFusion.import.warning-message'}
                components={{ bold: <strong /> }}
                />
        </div>
    )
}
