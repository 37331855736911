import React from "react";
import {useTranslation} from "react-i18next";
import VenueDataList from "../components/VenueDataList";

export default function RaceVenue() {
    const {t} = useTranslation();

    // render ---------------------------------------------------------
    return (
        <div>
            <div className="header">
                <div className="sub_header_title">
                    <span className="subtitle">{t('venue.subtitle')}</span>
                </div>
            </div>
            <div className="page-venues-body" style={{padding: 10}}>
                <VenueDataList />
            </div>
        </div>
    );
}



