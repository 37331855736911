import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from '../enums/language';
import ReactFlagsSelect from 'react-flags-select';
 
const Lang = () => {
    const { i18n } = useTranslation();
 
    // ReactFlagsSelect
    const [selected, set_selected] = useState("");
    const customLabels = {
      FR: { primary: "FR" },
      GB: { primary: "EN" },
      JP: { primary: "JP" },
    };


    let changeLanguage = (code) => {
        // let language = event.target.value;
        set_selected(code);
        switch (code) {
            case 'GB':
                i18n.changeLanguage(Language.EN);
                break;
            case 'JP':
                i18n.changeLanguage(Language.JP);
                break;
            case 'FR':
            default:
                i18n.changeLanguage(Language.FR);
                break;
        }
    }

    useEffect(() => {
      switch (i18n.language) {
        default:
        case 'fr':
          set_selected('FR')
        break
        case 'en':
          set_selected('GB')
        break
        case 'jp':
          set_selected('JP')
        break
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  
  
  
    return (
        <div>
            <div>
              <ReactFlagsSelect
                selected={selected}
                onSelect={changeLanguage}
                showSelectedLabel={false}
                showSecondarySelectedLabel={false}
                showOptionLabel={true}
                showSecondaryOptionLabel={false}
                customLabels={customLabels}
                countries={["FR", "GB", "JP"]}
                searchable={false}
              />                
            </div>
        </div>
    )
}
 
export default Lang;