import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Select} from 'antd';
import {useHistory} from 'react-router-dom';
import track from "../services/track.service";
import {useTranslation} from "react-i18next";
import Loader from "../components/loader"
import venue from "../services/venue.service";
import {Option} from "antd/lib/mentions";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function RaceTrackForm() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [venueList, setVenueList] = useState([]);
    const [fileName, setFileName] = useState("");

    const save = async (values) => {
        setLoading(true);
        track.getByCodeAndVenue(values.Code, values.Site)
            .then(res => {
                if (res) {
                    message.error(`${values.Site} ${values.Code} already exist !`, 4);
                } else {
                    values.file=file;
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("fileName", fileName);
                    formData.append("code", values.Code);
                    formData.append("label", values.Label);
                    formData.append("distance", values.Distance);
                    formData.append("venueCode", values.Site);
                    formData.append("type", values.Discipline);
                    track.save(formData).then(res => {
                        if (res.status !== 200 && res.status !== 204) {
                            message.error(t('Track.Error'));
                        } else {
                            message.success(t('Track.Saved'));
                        }
                        history.push('/track/');
                        setLoading(false);
                    })
                }
            })
            .catch(() => {
                message.error(t('track.Error'));
                setLoading(false);
            });
    }

    // handler --------------------------------------------------------
    function handleUpload(event) {
        if (event.target.files[0]) {
            form.setFieldsValue(extractInfoFromFileName(event.target.files[0].name));
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);
        }
    }

    function extractInfoFromFileName (fileName) {
        const elements = fileName.substring(0, fileName.indexOf('.')).split('_');
        if (elements.length !== 4 && elements.length !== 5) {
            return {
                fileName: fileName,
            }
        }
        return {
            fileName: fileName,
            Site: elements[0],
            Code: elements[1],
            Label: (elements.length === 5) ? elements[4].replaceAll('-', ' ') : elements[1],
            Discipline: elements[2],
            Distance: elements[3].replace(/[a-zA-Z_]/g, '')
        }
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        venue.listAll().then(data => setVenueList(data));
    }, [])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <Modal
                title={t('Track.InterpolationModal')}
                visible={loading}
                footer={[]}
                onCancel={() => setLoading(false)}>
                <span className="interpolation_modal_msg_wait">
                    {t('Track.InterpolationModalMsgWait')}
                </span>
                <Loader/>
            </Modal>
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {t('Track.New')}
                    </span>
                </div>
            </div>
            <Form
                {...layout}
                form={form}
                className="common_form"
                onFinish={save}>
                <Form.Item name="file" label={t('Track.File')} rules={[{required: true}]}>
                    <Input type="file" onChange={handleUpload}/>
                </Form.Item>

                <Form.Item name="fileName" label={t('Track.FileName')} rules={[{required: true}]}>
                    <Input readOnly={true} type="text"/>
                </Form.Item>

                <Form.Item name="Label" label={t('Track.Label')} rules={[{required: true}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="Site" label={t('Track.Site')} rules={[{required: true}]}>
                    <Select
                        className="psc_input select"
                        id="Site"
                        placeholder={t('RaceCalendar.Select_Site')}
                        allowClear
                        showSearch>
                        {
                            venueList.map((venue) => {
                                return (
                                    <Option
                                        key={venue.code}
                                        value={venue ? venue.code : ""}>
                                        <span>{venue.label}</span>
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                <Form.Item name="Discipline" label={t('Track.Discipline')} rules={[{required: true}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="Code" label={t('Track.Code')} rules={[{required: true}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="Distance" label={t('Track.Distance')} rules={[{required: true}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="submit">
                        {t('Track.Save')}
                    </Button>

                    <Button type="primary" htmlType="submit" onClick={() => history.push("/track")}>
                        {t('Track.Cancel')}
                    </Button>
                </Form.Item>

            </Form>

        </div>


    )
}