import React, {useEffect, useState} from "react";
import {HashRouter, Route, Switch, useHistory, useLocation} from "react-router-dom";
import PrivateRoute from "./services/privateRoute";
// components
import PublicLayout from './components/publicLayout'
import PrivateLayout from './components/privateLayout'
import Loader from "./components/loader"
import Login from "./pages/login";
import RaceCalendar from "./pages/raceCalendar";
import RaceTrackAndVenue from "./pages/RaceTrackAndVenue";
import TrackerFusionList from "./pages/trackerFusionList";
import TrackerFusionForm from "./pages/trackerFusionForm";
import TrackerFusionImport from "./pages/trackerFusionImport";
import PrivateUserList from "./pages/privateUserList";
import PrivateMonitoring from "./pages/privateMonitoring";
import PrivateUserForm from "./pages/privateUserForm";
import PrivateRaceForm from "./pages/privateRaceForm";
import PrivateSettings from "./pages/privateSettings";
import RaceTrackForm from "./pages/raceTrackForm";
import VenueTrackForm from "./pages/venueTrackForm";
import AssignTracker from "./pages/assignTracker";
import settings from "./services/settings.service";
import instance from "./services/instance.service";

// css
import "antd/dist/antd.min.css";
import './styles/_vars.css';
import './styles/base.css';
import './styles/public_layout.css';
import './styles/login.css';
import './styles/private_layout.css';
import './styles/terminal_console.css';
import './styles/private_page.css';
import './styles/list.css';
import './styles/form.css';
import './styles/settings.css';
import './styles/private_external_data.css';
import './styles/private_race_calendar.css';
import './styles/private_race_sectionnal_live.css';
import './styles/Impfiles.css';
import './styles/trackerFusion.css';
import './styles/pilotage.css';
import './styles/pilotageMapToolbar.css';
import './styles/pilotageSearchCriteria.css';
import './styles/track.css';


function App() {
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (history.location.hash === '#/') {
            history.push(`#/calendar`);
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        settings.get().then(res => {
            if (res && res.data && res.data.length === 1) {
                localStorage.setItem("mapDefaultCenter", res.data[0].map_default_center);
                localStorage.setItem("mapDefaultZoom", res.data[0].map_default_zoom);
                setLoading(false);
            }
        });
        document.title = `McLloyd RACING ${instance.getInstanceName()}`;
    }, []);


    return (

        <>
            {loading ? <Loader/> :
                <Switch>
                    <HashRouter>

                        {/* public pages */}
                        <Route path={["/login"]}>
                            <PublicLayout>
                                <Login/>
                            </PublicLayout>
                        </Route>

                        {/* private pages */}
                        <PrivateRoute path={["/calendar"]}>
                            <PrivateLayout>
                                <RaceCalendar/>
                            </PrivateLayout>

                        </PrivateRoute>

                        <PrivateRoute path={["/track/:selected?"]}>
                            <PrivateLayout>
                                <RaceTrackAndVenue />
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/monitoring/:raceUuid?"]}>
                            <PrivateLayout>
                                <PrivateMonitoring/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/fusions"]}>
                            <PrivateLayout>
                                <TrackerFusionList/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/fusion/:id?"]}>
                            <PrivateLayout>
                                <TrackerFusionForm/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/fusion-import/"]}>
                            <PrivateLayout>
                                <TrackerFusionImport/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/users/:id?"]}>
                            <PrivateLayout>
                                <PrivateUserList/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/race/:uuid?"]}>
                            <PrivateLayout>
                                <PrivateRaceForm/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/track_form/:id?"]}>
                            <PrivateLayout>
                                <RaceTrackForm/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/venue_form/"]}>
                            <PrivateLayout>
                                <VenueTrackForm/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/user/:uuid?"]}>
                            <PrivateLayout>
                                <PrivateUserForm/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/assign"]}>
                            <PrivateLayout>
                                <AssignTracker/>
                            </PrivateLayout>
                        </PrivateRoute>

                        <PrivateRoute path={["/settings"]}>
                            <PrivateLayout>
                                <PrivateSettings/>
                            </PrivateLayout>
                        </PrivateRoute>

                    </HashRouter>
                </Switch>}
        </>
    )
}

export default App;