import React, {useEffect, useState} from "react";
import {Badge, Button, Drawer, Layout, Menu, Select, Timeline, Tooltip} from "antd";
import {
    BlockOutlined,
    ClockCircleOutlined,
    LinkOutlined,
    LogoutOutlined,
    NodeIndexOutlined,
    ScheduleOutlined,
    SettingOutlined,
    TeamOutlined,
    WifiOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone
} from "@ant-design/icons";
import {useAuth} from '../services/auth.service';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Lang from "./lang";
import Loader from "../components/loader"
import venue from "../services/venue.service";
import race from "../services/race.service";
import positionServer from "../services/position-server.service";
import useInterval from "react-useinterval";
import event from "../services/event.service";
import instance from "../services/instance.service";
const {Option} = Select;
const {Sider, Content} = Layout;

const GET_EVENT_LIST_FREQUENCY = 3000;

export default function PrivateLayout(props) {
    const {t} = useTranslation();
    const auth = useAuth();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [venueList, setVenueList] = useState([]);
    const [activatedVenue, setActivatedVenue] = useState({});
    const [raceEventList, setRaceEventList] = useState([]);
    // eslint-disable-next-line
    const [hpv2Selected, setHpv2Selected] = useState('');
    const [latestRace, setLatestRace] = useState();
    const [collapsed, setCollpased] = useState(true);
    const [user, setUser] = useState({});
    const [unreadEventsCount, setUnreadEventsCount] = useState(0);
    const [eventListVisible, setEventListVisible] = useState(false);

    const showDrawer = () => {
        setEventListVisible(true);
        setUnreadEventsCount(0);
        sessionStorage.setItem("readEvents", raceEventList.map(event => event.uuid));
    };

    const onClose = () => {
        setEventListVisible(false);
    };

    const getCurrentHpv2Server = () => {
        // retrieve the current selected server in db
        positionServer.getSelected().then(hpv2Server => {
            if (hpv2Server && hpv2Server.data) {
                setHpv2Selected(hpv2Server.data.name)
            }
        });
    }

    const venueSelectionHandler = (selectedVenue) => {
        if (selectedVenue) {
            venue.activate({venue: selectedVenue}).then(() => getActivatedVenue());
        } else {
            venue.deactivate().then(() => getActivatedVenue());
        }
    }

    const getActivatedVenue = () => {
        venue.getActivatedVenue().then(result => {
            setActivatedVenue(result.data);
        });
    }

    const getVenueList = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth()+1);
        const day = today.getDate()
        const todayAsString = year + '-' + (month<10?'0':'') + month + '-' + (day<10?'0':'') + day
        venue.list(todayAsString).then(result => {
            if (result && result.data) setVenueList(result.data);
        });
    }

    const getEventList = () => {
        event.getListByDate()
            .then(events => {
                if (events && events.data) {
                    if (!eventListVisible) {
                        if (sessionStorage.getItem("readEvents") !== null) {
                            const readEvents = sessionStorage.getItem("readEvents").split(',');
                            setUnreadEventsCount(events.data.filter(event => !readEvents.includes(event.uuid)).length);
                        } else {
                            setUnreadEventsCount(events.data.length);
                        }
                    }
                    setRaceEventList(events.data);
               } else {
                    setRaceEventList([]);
                }
            })
            .catch(err => {
                console.log('ERROR', err);
            });
    }

    const getRaceState = () => {
        if (activatedVenue && activatedVenue.code) {
            race.getLatestRaceState(activatedVenue.code).then(race => {
                if (race.data) {
                    setLatestRace(race.data);
                }
            });
        } else {
            setLatestRace(null);
        }
    }

    useInterval( () => {
        getEventList();
        getRaceState();
    },GET_EVENT_LIST_FREQUENCY);

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setLoading(false);
        setCollpased(true);
        setUser(JSON.parse(localStorage.getItem('user')));
        getCurrentHpv2Server();
        getActivatedVenue();
        getVenueList();
        getEventList();
        getRaceState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // render ---------------------------------------------------------
    return (
        <>{loading ? <Loader/> : <Layout className="private_layout">
            <Sider collapsible className="sider" trigger={null} collapsed={collapsed}>
                <div className="logo">
                    <Link to="/calendar">
                        <img src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} alt="McLloyd"/>
                    </Link>
                </div>
                <div className="menu_wrapper">
                    <Menu mode="inline" className="menu">
                        <Menu.Item key="1" icon={<ScheduleOutlined/>}>
                            <Link to="/calendar">{t('Menu.RaceCalendar')}</Link>
                        </Menu.Item>

                        <Menu.Item key="2" icon={<NodeIndexOutlined/>}>
                            <Link to="/track">{t('Menu.RaceTrack')}</Link>
                        </Menu.Item>

                        <Menu.Item key="4" icon={<WifiOutlined/>}>
                            <Link to="/monitoring">{t('Menu.RaceMonitoring')}</Link>
                        </Menu.Item>

                        <Menu.Item key="5" icon={<BlockOutlined/>}>
                            <Link to="/fusions">{t('Menu.MergeTracker')}</Link>
                        </Menu.Item>

                        <Menu.Item key="6" icon={<LinkOutlined/>}>
                            <Link to="/assign">{t('Menu.Assignments')}</Link>
                        </Menu.Item>
                        <Menu.Divider/>
                    </Menu>
                    <div className="menu middle">
                        <div className="server">
                            <div className="name">{hpv2Selected}</div>
                            <div className="label">SERVER</div>
                        </div>
                        <div className="event-list">
                            <Button type="primary" className="" onClick={showDrawer}>
                                <ClockCircleOutlined />
                                <Badge count={unreadEventsCount}>
                                </Badge>
                            </Button>
                            <Drawer title={t('Event list Display')} onClose={onClose} visible={eventListVisible}
                                    placement='right' width={'33%'}
                                    id={'event_list'}>
                                <div id={'event_list'}>
                                    <Timeline mode="alternate">
                                        {raceEventList.map(raceEvent => {
                                            return raceEvent.rawEventContent?
                                             <Tooltip title={raceEvent.rawEventContent}
                                                      placement="left"
                                                      key={`tooltip-${raceEvent.uuid}`}
                                                      color={"#dbac58"}
                                                      arrowPointAtCenter={true}
                                                      overlayInnerStyle={{color: 'black'}}>
                                                <Timeline.Item
                                                    key={raceEvent.uuid}
                                                    color={raceEvent.origin === 'OPERATOR' ? 'green' : 'blue'}>
                                                    {new Date(raceEvent.createdAt).toLocaleTimeString([],
                                                        {hour: '2-digit', minute:'2-digit', second:'2-digit'})} <br/>
                                                    {t("monitoring.meetingShortcut")+`${raceEvent.race.meeting}`+t("monitoring.raceShortcut")+`${raceEvent.race.number}`}
                                                    <br/> {raceEvent.type}
                                                </Timeline.Item>
                                            </Tooltip>
                                                :
                                                <Timeline.Item
                                                    key={raceEvent.uuid}
                                                    color={raceEvent.origin === 'OPERATOR' ? 'green' : 'blue'}>
                                                    {new Date(raceEvent.createdAt).toLocaleTimeString([],
                                                        {hour: '2-digit', minute:'2-digit', second:'2-digit'})} <br/>
                                                    {t("monitoring.meetingShortcut")+`${raceEvent.race.meeting}`+t("monitoring.raceShortcut")+`${raceEvent.race.number}`}
                                                    <br/> {raceEvent.type}
                                                </Timeline.Item>
                                        })}
                                    </Timeline>
                                </div>
                            </Drawer>
                            {latestRace ?
                            <div>
                                R{latestRace.meeting}-C{latestRace.number} &nbsp;
                                {latestRace.isFinished ?
                                    <CloseCircleTwoTone twoToneColor="#FC5050"/>
                                    : <CheckCircleTwoTone twoToneColor="#0acf65"/>
                                }
                            </div>
                                : ''
                            }
                        </div>
                        {/*env variables are passed as string instead of boolean*/}
                        {/*https://medium.com/@maxbeatty/environment-variables-in-node-js-28e951631801#0227*/}
                        {process.env.REACT_APP_AUTOMATIC_MONITORING_ENABLE === "true" ?
                        <div className="auto-monitoring">
                            <Select onChange={venueSelectionHandler}
                                    className="auto-monitoring-select"
                                    value={activatedVenue !== null ? activatedVenue.uuid : ''}
                                    title={activatedVenue !== null ? activatedVenue.label : ''}
                                    allowClear>
                                {venueList.map(venue => {
                                    return <Option key={venue.code} value={venue.uuid} title={venue.label}>
                                        {venue.code}
                                    </Option>
                                })}
                            </Select>
                            <div style={{color: 'white'}}>
                                VENUE
                            </div>
                        </div>
                         : ''}
                    </div>
                    <Menu mode="inline" className="menu ">
                        <Menu.Divider/>
                        <Lang/>
                        {user.roles && user.roles.includes("ROLE_ADMIN") && <>
                            <Menu.Item key="8" icon={<TeamOutlined/>}>
                                <Link to="/users">{t('Menu.Users')}</Link>
                            </Menu.Item>
                        </>
                        }
                        <Menu.Item key="9" icon={<SettingOutlined/>}>
                            <Link to="/settings">{t('Menu.Settings')}</Link>
                        </Menu.Item>
                        <Menu.Item key="10" onClick={() => auth.logout()} icon={<LogoutOutlined/>}>
                            logout
                        </Menu.Item>
                        <Menu.Divider/>
                    </Menu>
                    <div className="version">{`${instance.getInstanceName()}`}</div>
                    <div className="version">v {`${process.env.REACT_APP_VERSION}`}</div>
                </div>
            </Sider>
            <Layout id="page_wrapper" className="layout">
                <Content>{props.children}</Content>
            </Layout>
        </Layout>}</>
    )
}