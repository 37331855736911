import axios from "axios";
import authHeader from "./auth-header";

// load -------------------------------------------------------------
const get = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/', method: 'get', headers: authHeader(), data: 1
    }).catch(function (error) {
        console.log(error.response?.data);
    })
}

const getMecActivatedSetting = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/mec-activated', method: 'get', headers: authHeader(), data: 1
    }).catch(function (error) {
        console.log(error.response?.data);
    })
}

const getEngineParameters = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/engine',
        method: 'get',
        headers: authHeader()
    })
    .then(response => response.data)
    .catch(function (error) {
        console.log(error.response?.data);
    })
}

const getEngineParametersChoices = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/engine/choices',
        method: 'get',
        headers: authHeader(),
    })
    .then(response => response.data)
    .catch(function (error) {
        console.warn(error.response?.data);
    })
}

const setEngineParametersChoice = async (choice) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/engine/choices',
        method: 'post',
        headers: authHeader(),
        data: {
            'filename': choice,
        },
    })
    .then(response => response.data)
    .catch(function (error) {
        console.warn(error.response?.data);
    })
}

// save -------------------------------------------------------------
const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response?.data);
    })
}

const getMonitoringColumns = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/settings/monitoring-columns', method: 'get', headers: authHeader(), data: 1
    }).then(response => response.data).catch((error) => {
        console.warn(error.response?.data);
        return [];
    })
}

// default export ---------------------------------------------------
const settings = {
    get,
    save,
    getMecActivatedSetting,
    getEngineParameters,
    getEngineParametersChoices,
    setEngineParametersChoice,
    getMonitoringColumns,
};

export default settings;