import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./auth.service";
import Loader from '../components/loader'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    if (!auth.user) {
      auth.auth_from_storage().then(ret => {
        auth.user = ret;
        set_loading(false);
      })
    } else {
      set_loading(false);
    }
    return () => {set_loading(false)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    {loading ? <Loader /> : (
      <Route
        {...rest}
        render={({ location }) => 
          auth.user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />)}
    </>)
}