import React, {useEffect, useState} from "react";
import user from "../services/user.service";
import {Button, Modal, Table, Tag, Tooltip} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined,} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {confirm} = Modal;

export default function PrivateUserList() {

    const {t} = useTranslation();

    const [loading, set_loading] = useState(false);
    const [data, set_data] = useState([]);
    const history = useHistory();

    const columns = [
        {
            title: t('utilisateur.Nom_Form'),
            dataIndex: 'username',
            render: (value, record) => {
                return <>
                    <p key={record.uuid + "_name"}>{value}</p>
                </>
            }
        },
        {
            title: t('utilisateur.email'),
            dataIndex: 'email',
            render: (value, record) => {
                return <>
                    <p key={record.uuid + "_email"}>{value}</p>
                </>
            }
        },
        {
            title: t('utilisateur.Roles_Form'),
            dataIndex: 'roles',
            render: (roles, record) => (
                <>
                    {roles.map(role => {
                        return (
                            <Tag key={record.uuid + '_' + role.name}>
                                {role.name}
                            </Tag>
                        )
                    })}
                </>
            )
        },
        {
            title: t('utilisateur.Active_Form'),
            dataIndex: 'active',
            key: 'active',
            render: (value) => {
                if (value) {
                    return <CheckCircleOutlined className="user_active"/>
                } else {
                    return <CloseCircleOutlined className="user_disabled"/>
                }
            }
        },
        {
            title: t('utilisateur.Action_Form'),
            dataIndex: 'action',
            key: 'action',
            className: 'col_action',
            render: (value, record) => {
                return <>
                    <Tooltip title={t('utilisateur.Modifier')}>
                        <Button onClick={(e) => edit(e, record.uuid)} shape="circle" icon={<EditOutlined/>}/>
                    </Tooltip>
                    <Tooltip title={t('utilisateur.supprimer')}>
                        <Button onClick={(e) => del(e, record.uuid)} shape="circle" icon={<DeleteOutlined/>}/>
                    </Tooltip>
                </>
            }
        },
    ]

    // list -----------------------------------------------------------
    const list = async () => {
        user
            .list()
            .then(r => {
                if (r.data) {
                    set_data(r.data);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    // create ---------------------------------------------------------
    const create = () => {
        history.push('/user/');
    }

    // edit -----------------------------------------------------------
    const edit = (e, uuid) => {
        history.push('/user/' + uuid);
    }

    // list -----------------------------------------------------------
    const del = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: 'Confirmer la suppression ?',
            content: 'La suppression est définitive',
            onOk() {
                return new Promise(async (resolve) => {
                    await user.del(uuid);
                    await list();
                    resolve();
                });
            },
            onCancel() {
            },
        });
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        set_loading(true);
        list();
        set_loading(false);
    }, [])


    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">

            <div className="header">
                <div className="sub_header_title">
                    <span className="title"> {t('utilisateur.title')}</span>
                    <span className="subtitle">{t('utilisateur.subtitle')}</span>
                </div>
                <div className="sub_header_action">
                    <Button onClick={create}>{t('utilisateur.Btn_cree')}</Button></div>
            </div>

            <div className="data_list">
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="uuid"
                    loading={loading}
                    id="user_list"
                    pagination={{pageSize: 20}}
                />
            </div>
        </div>
    )

}