import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from '../services/auth.service';
import { Form, Input, Button, message, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import instance from "../services/instance.service";

export default function Login() {
  const [loading, set_loading] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  // form submit
  const onSubmit = (form_data) => {
    set_loading(true);
    auth.login(form_data.login, form_data.password).then(
      () => {
        set_loading(false);
        history.push(`/calendar`);
      },
      (error) => {
        const resMessage = error.response?.data?.message || error.message || error.toString();
        message.error(resMessage);
        set_loading(false);
      }
    );
  };

  // form failed
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // no login for connected user
  const check_connection = async () => {
    set_loading(true);
    if (await auth.auth_from_storage()) {
      let { from } = location.state || { from: { pathname: `/calendar` } };
      history.replace(from);
    }
    set_loading(false);
  } 

  // useEffect ------------------------------------------------------
  useEffect(() => {
    check_connection();
    return () => {
      set_loading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // render ---------------------------------------------------------
  return (
    <div className="public_page_wrapper login">
      <Card className="public_home_overlay login_overlay">
        <Form
          name="login_form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
          className="login_form"
          layout="vertical"
        >
          <Form.Item
            name="login"
            rules={[{ required: true, message: "Please Enter Username" }]}
            label="User"
            className="login_input_text"
          >
            <Input
              disabled={loading}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="login"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please Enter Password" },
            ]}
            className="login_input_text"
          >
            <Input.Password
              disabled={loading}
              prefix={<LockOutlined style={{ fontSize: 13 }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={() => {
                set_loading(!loading);
              }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div className="version">{`${instance.getInstanceName()}`}</div>
      <div className="version">Version {`${process.env.REACT_APP_VERSION}`}</div>
    </div>
  );
};

