import React, {useState, useEffect} from "react";
import { Form, Input, Button } from 'antd';

// props {
//   name  
//   label
//   label_change
//   required
//   change
// }
export function FormItemPassword(props) {
  const [pass_change, set_pass_change] = useState(false);

  useEffect(() => {
    if (props.change) {
      set_pass_change(true);
    }
  }, [props])  

  return (
      (!pass_change) ?
        <Form.Item                
          key={props.name}
          label={props.label}  
        >
          <Button key={props.name + "btn"}
            onClick={() => set_pass_change(true) }
          >
            {props.label_change || 'Change my password'}
          </Button>
        </Form.Item>
        :
        <div key={props.name + "formitemwrapper"}>
          <Form.Item                
            key={props.name + "formitem"}
            name={props.name}
            label={props.label}
            dependencies={['confirm_pass']}
            hasFeedback
            rules={[
              {
                required: props.required,
                message: 'Please input your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && getFieldValue("confirm_pass") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password key={props.name} name={props.name} />
          </Form.Item>
  
          <Form.Item
            key={props.name + "formconfirm"}
            name="confirm_pass"
            label="Confirm Password "
            dependencies={[props.name]}
            hasFeedback
            rules={[
              {
                required: props.required,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && getFieldValue(props.name) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password key={props.name + "confirm"} name={props.name + "confirm"}/>
          </Form.Item>
        </div>
  )
}