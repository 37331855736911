import axios from "axios";
import authHeader from "./auth-header";

const restartApplication = async (app, ip, password,) => {
    return await axios({
        method: 'get',
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/server/application/${app}/restart`,
        headers: authHeader(),
        params: {
            ip,
            password,
        }
    })
}

const service = {
    restartApplication,
};

export default service;
