import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import VenueService from "../services/venue.service";
import TrackService from "../services/track.service";

const styleHead = {
    backgroundColor: '#E3E9EE',
    border: '0 solid #DDDDDD',
}

const styleLineEven = {
    backgroundColor: '#FFFFFF',
    border: '0 solid #DDDDDD',
}

const styleLineOdd = {
    backgroundColor: '#F0F0F0',
    border: '0 solid #DDDDDD',
}

const styleCellCode = {
    textAlign: 'center',
    width: 75,
}

const styleCellLabel = {
    textAlign: 'left',
    minWidth: 200,
}

const styleCellNbTracks = {
    textAlign: 'right',
    minWidth: 75,
    paddingRight: 10,
}

function VenueDataRow({venue, index}) {
    return (
        <tr style={index%2==0 ? styleLineEven : styleLineOdd}>
            <td style={styleCellCode}>{venue.code}</td>
            <td style={styleCellLabel}>{venue.label}</td>
            <td style={styleCellNbTracks}>{venue.nbTracks}</td>
        </tr>
    )
}

export default function VenueDataList() {
    const {t} = useTranslation();

    const [venues, setVenues] = useState([]);

    useEffect(() => {
        VenueService.listAll().then(venues => {
            TrackService.listAll().then(tracks => {
                tracks.map(track => {
                    const venue = venues.find(v => v.code === track.venueCode)
                    if (venue) {
                        venue.nbTracks = venue.nbTracks || 0;
                        venue.nbTracks++;
                    }
                })
                setVenues(venues.sort((v1, v2) => v1.label < v2.label ? -1 : 1));
            })

        });
    }, []);

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th style={styleHead}>{t("venue.table.code")}</th>
                        <th style={styleHead}>{t("venue.table.label")}</th>
                        <th style={styleHead}>{t("venue.table.nbTracks")}</th>
                    </tr>
                </thead>
                <tbody>
                    { venues.map((v, i) => <VenueDataRow key={v.code} venue={v} index={i} />) }
                </tbody>
            </table>
        </div>
    )
}
