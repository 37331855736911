import React, { useRef} from "react";
import RaceCalendarDataList from "../components/raceCalendarDataList"
import { useTranslation } from "react-i18next";
import {
  Button
} from "antd";
import {
  EditOutlined
} from "@ant-design/icons";

export default function RaceCalendar() {
  const { t } = useTranslation();  
  const data_list_ref = useRef();

  // add_race -------------------------------------------------------
  const add_race = () => {
    data_list_ref.current.add_race_ext();
  }

  // render ---------------------------------------------------------
  return (
    <div className="private_page_wrapper">
        <div className="header">
          <div className="sub_header_title">
            <span className="title">{t('RaceCalendar.Title')}</span>
            <span className="subtitle">
              {t('RaceCalendar.SubTitle')}
            </span>
          </div>
          <div className="sub_header_action">
            <Button
              className="toolbar_btn"
              onClick={add_race}
              icon={<EditOutlined />}>
              {t("common.add")}
            </Button>{" "}
          </div>
        </div>
        <RaceCalendarDataList ref={data_list_ref}/>
      </div>
  );
}
