import React, {useState} from "react";
import {Button, Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Papa from 'papaparse';
// INTERNAL
import FusionService from "../services/fusion.service";
import TrackerFusionImportInformation from "./TrackerFusionImportInformation";
import TrackerFusionImportErrors, { checkErrors } from "./TrackerFusionImportErrors";

const layout = {
    labelCol: {span: 8}, wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};
export default function TrackerFusionImport() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();
    const [file, setFile] = useState();
    const [errors, setErrors] = useState([]);

    const save = () => {
        if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
            console.log('The File APIs are not fully supported in this browser.');
            message.error(t('common.error'))
            return false;
        }
        if (!file) {
            console.log("This browser doesn't seem to support the `files` property of file inputs.");
            console.log("No file selected.");
            message.error(t('common.error'))
            return false;
        } else {
            Papa.parse(file, {
                keepEmptyRows:false,
                skipEmptyLines: true,
                complete: results => {
                    const {errors, fusions} = checkErrors(t, results)
                    if (errors.length > 0) {
                        setErrors(errors);
                    } else {
                        setErrors([]);
                        Promise.resolve()
                            .then(() => FusionService.listAll())
                            .then(list => list.map(f => f.id))
                            .then(list => FusionService.delAll(list, false))
                            .then(response => response.errors.length > 0
                                ?   Promise.reject({
                                        toUser : {
                                            code: 'UNABLE_TO_CLEAN',
                                            errors: response.errors,
                                        },
                                    })
                                :   Promise.resolve()
                            )
                            .then(() => FusionService.saveAll(fusions, false))
                            .then(response =>
                                response.errors.length > 0
                                ?   Promise.reject({
                                        toUser : {
                                            code: 'UNABLE_TO_CREATE_SOME',
                                            errors: response.errors,
                                        },
                                    })
                                :   Promise.resolve()
                            )
                            .then(() => {
                                message.success(t('TrackerFusion.importSuccessMessage'));
                                history.push("/fusions");
                            })
                            .catch(error => {
                                if (error?.toUser?.code === 'UNABLE_TO_CLEAN') {
                                    message.error(t('TrackerFusion.error.import-UNABLE_TO_CLEAN'))
                                } else if (error?.toUser?.code === 'UNABLE_TO_CREATE_SOME') {
                                    message.error(t('TrackerFusion.error.import-UNABLE_TO_CREATE_SOME', {list: error.toUser.errors.join(',')}))
                                } else {
                                    message.error(t('common.error'))
                                }
                            })
                    }
                }
            });
        }
    }

    function handleUpload(event) {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    }

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {t('TrackerFusion.importFusionTitle')}
                    </span>
                </div>
            </div>
            <Form
                {...layout}
                form={form}
                className="common_form import_merge_form"
                style={{maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'}}
                onFinish={save}>
                <Form.Item name="file" label={t('TrackerFusion.chooseFileToImport')} rules={[{required: true}]}>
                    <Input type="file" onChange={handleUpload}/>
                </Form.Item>
                <TrackerFusionImportInformation />
                <TrackerFusionImportErrors errors={errors} />
                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="cancel" onClick={() => history.push("/fusions")} className="import-action-button">
                        {t('common.back')}
                    </Button>
                    <Button type="primary" htmlType="submit" className="import-action-button">
                        {t('TrackerFusion.import.save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}