import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, Collapse, DatePicker, Select, Table, Tooltip} from "antd";

import {ArrowDownOutlined, CalendarOutlined, NodeIndexOutlined, ClearOutlined} from "@ant-design/icons";
import moment from "moment";
import fusion from "../services/fusion.service";
import venue from "../services/venue.service";
import competitor from "../services/competitor.service";
import horseColor from "../classes/horseColor";
import Loader from "../components/loader"
import _ from "lodash";
import {NON_STARTER_STATUS_LIST, STARTER_STATUS_LIST} from "../enums/startStatus";

const {Panel} = Collapse;
const {Option} = Select;
const dateFormat = "YYYY-MM-DD";

export default function AssignTracker() {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [isParameterSet, setIsParameterSet] = useState(false);

    const [availableFusions, setAvailableFusions] = useState([]);
    const [dataMeeting, setDataMeeting] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedVenue, setSelectedVenue] = useState();
    const [venueList, setVenueList] = useState([]);
    const [value, setValue] = useState(0);
    const [currentOpenedTab, setCurrentOpenedTab] = useState([]);

    const getFusionList = () => {
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    setAvailableFusions(r.data);
                }
            });
    }

    const getVenueList = async () => {
        venue.list(selectedDate.format("YYYY-MM-DD"))
            .then((r) => {
                if (r && r.data) {
                    setVenueList(r.data);
                }
            });
    };

    const loadData = async () => {
        // load from race to get all races
        let competitorsByRace = await competitor.getListByDateAndVenue(selectedDate.format(dateFormat), selectedVenue);
        if (competitorsByRace.data) {
            competitorsByRace.data.forEach(race => {
                // add race uuid to each competitor to make it easier to update race when a row is updated
                race.competitors.forEach(competitor => {
                    competitor.raceUuid = race.uuid;
                })
            })
            setDataMeeting(competitorsByRace.data)
            // setUp Current open tab as the first one only
            setCurrentOpenedTab([competitorsByRace.data[0].uuid+'-'+competitorsByRace.data[0].number]);
        } else {
            setDataMeeting([])
        }
        setLoading(false);
    }

    const saveCompetitor = (r) => {
        return competitor.save(r);
    }

    // handlers -------------------------------------------------------

    const datepicker_handler = (date) => {
        setSelectedDate(date);
        setSelectedVenue(null);
    }

    const venueHandler = (venue) => {
        setDataMeeting([]);
        setLoading(true);
        setSelectedVenue(venue);
    }

    const findRaceFromCompetitor = (updatedCompetitor) => {
        return dataMeeting.find(race => race.uuid === updatedCompetitor.raceUuid);
    }

    const findCompetitorToUpdate = (updatedCompetitor) => {
        let race = findRaceFromCompetitor(updatedCompetitor);
        if (race && value >=0) {
            let competitors = race.competitors;
            let competitor = competitors.find(c => c.uuid === updatedCompetitor.uuid);
            if (competitor) {
                return competitor;
            }
        }
        return undefined;
    }

    const cellStatusHandler = (v, updatedCompetitor) => {
        const competitor = findCompetitorToUpdate(updatedCompetitor);
        if (competitor) {
            competitor.status = v;
            saveCompetitor(competitor).then(() => {
                if (['NP'].includes(v)) {
                    cellFusionHandler('-', updatedCompetitor);
                }
            });
            setValue(value => value + 1);
        }
    }

    const resetAll = (race) => {
        race.competitors.forEach(competitor => {
            cellFusionHandler(null, competitor);
        })
    }

    const cellFusionHandler = (v, updatedCompetitor) => {
        const competitor = findCompetitorToUpdate(updatedCompetitor);
        if (competitor) {
            competitor.fusionId = v;
            saveCompetitor(competitor).then(() => {});
            setValue(value => value + 1);
        }
    }

    const handleCollapse = (openedPanelList, currentPanel) => {
        // if the element that triggers the event is included in the list, that means it has be opened
        if (openedPanelList.includes(currentPanel)) {
            currentOpenedTab.push(currentPanel);
            // close instead
        } else {
            _.remove(currentOpenedTab, function(n) { return n === currentPanel;});
        }
    }

    const autoAffectFusion = (e, record) => {
        const competitor = findCompetitorToUpdate(record);
        let fusionIdToSet = (record.fusionId) ? Number(record.fusionId) : 1;
        let race = dataMeeting.find(race => race.uuid === record.raceUuid);
        if (race) {
            let applyFusion = false;
            for (let i in race.competitors) {
                const _competitor = race.competitors[i];
                if (_competitor.uuid === competitor.uuid) {
                    applyFusion = true;
                }
                if (applyFusion && _competitor.status !== 'NP') {
                    _competitor.fusionId = fusionIdToSet;
                    fusionIdToSet++;
                    fusionIdToSet = (fusionIdToSet > Math.max( ...availableFusions.map(data=> data.fusionId))) ?
                        Math.min( ...availableFusions.map(data=> data.fusionId) ) : fusionIdToSet;
                    saveCompetitor(_competitor);
                }
            }
            // Force redraw cause model change is done out of action human interaction
            // @TODO Try later to find a more elegant way to force redraw.
            setValue(value => value + 1);
        }
    }

    // on date selection, load data from db
    useEffect(() => {
        // reset selection
        if (!selectedDate && !selectedVenue) {
            setDataMeeting([]);
            setVenueList([]);
        }
        if (!selectedDate && selectedVenue) {
            setDataMeeting([]);
            setVenueList([]);
            setSelectedVenue(null);
        }
        if (selectedDate && !selectedVenue) {
            getVenueList().then();
        }
        if (selectedDate && selectedVenue) {
            setIsParameterSet(true);
            loadData().then();
            getFusionList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedVenue]);

    // race columns ---------------------------------------------------
    const columns = [
        {title: t('monitoring.competitorNumber'), dataIndex: "number", className: 'sec-col-num', render: (value, record) => {
            let color = horseColor(value);
            return (<Avatar
                style={{
                    backgroundColor: `#${color.color}`,
                    color: `#${color.font}`,
                    border: `1px solid #${color.border}`,
                    verticalAlign: 'middle'
                }}
                size={25}>
                {record.number}
            </Avatar>)
            },
        },
        {title: t('monitoring.status'), dataIndex: "status", render: (value, record) => {
            return (<Select value={value} onChange={v => cellStatusHandler(v, record)}>
                {
                    STARTER_STATUS_LIST.map(status => {
                        return <Option
                            key={`${record.uuid}-${status}`}
                            value={status}>
                            <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                        </Option>
                    })
                }
                {
                    NON_STARTER_STATUS_LIST.map(status => {
                        return <Option
                            key={`${record.uuid}-${status}`}
                            value={status}>
                            <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                        </Option>
                    })
                }
                </Select>)
            }
        },
        {title: t('monitoring.fusion'), dataIndex: "fusionId", render: (value, record) => {
            return (<div className="cell_fusion_wrapper">
                <Select
                    value={value}
                    disabled={record.status === 'NP'}
                    onChange={v => cellFusionHandler(v, record)}>
                    <Option key={`null_${record.uuid}`} value={null}>-</Option>
                    {availableFusions.map(fusion => {
                        return <Option
                            key={`${record.uuid}-${fusion.fusionId}`}
                            disabled={
                                findRaceFromCompetitor(record).competitors.filter(
                                    competitor => competitor.fusionId === fusion.fusionId).length > 0}
                            value={fusion.fusionId}>
                            {fusion.fusionId}
                        </Option>
                    })}
                </Select>
                <Button onClick={(e) => autoAffectFusion(e, record)}
                        shape="circle"
                        disabled={record.status === 'NP' || value === null}
                        icon={<ArrowDownOutlined/>}/>
                </div>)
            }
        },
        {title: t('monitoring.trackers'), dataIndex: "fusionId", render: (value) => {
            if (availableFusions && value) {
                // get from the availableFusions retrieve from the server the list of trackers associated with the fusionId
                const fusion = availableFusions.find(fusion => fusion.fusionId === value);
                if (fusion) {
                    return <div className="capt_cell">
                        {
                            fusion.trackers.map((c, i) => {
                                return <span key={`${i}-${c}`}>{c}</span>
                            })
                        }
                    </div>
                } else {
                    return <div className="capt_cell"><span>{t('monitoring.empty')}</span></div>
                }
            } else {
                return <div className="capt_cell"><span>{t('monitoring.empty')}</span></div>
            }
        }
    }
    ];

    const TrackerTable = () => {
        if (loading) {
            return <Loader/>
        }
        return <div className="data_list programme tracker_table_wrapper">
            {dataMeeting.map((race) => {
                    const panelKey = race.uuid+'-'+race.number;
                    return <Collapse key={`${race.uuid}`}
                                     onChange={v => handleCollapse(v, panelKey)}
                                     defaultActiveKey={`${(currentOpenedTab.includes(panelKey))? panelKey:''}`}>
                        <Panel className="tracker_race_number"
                               header={`C${race.number} : ${new Date(race.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}
                               key={`${panelKey}`}>
                            <span className="tracker_race_number">
                                {race.label}
                                <Button className="reset_all_fusion_button" onClick={() => resetAll(race)} shape="circle" icon={<ClearOutlined />}/>
                            </span>
                            <Table
                                bordered
                                columns={columns}
                                dataSource={race.competitors}
                                loading={loading}
                                rowKey="uuid"
                                className="common_list pilotage_list tracker_table"
                                pagination={false}
                                rowClassName={(record) => {
                                    if (NON_STARTER_STATUS_LIST.includes(record.status)) {
                                        return 'editable-row disabled';
                                    }
                                    return 'editable-row';
                                }}
                            />
                        </Panel>
                    </Collapse>
                }
            )}
        </div>
    }

    // render ---------------------------------------------------------
    return (<div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">{t('AssignTracker.Title')}</span>
                    <span className="subtitle">
              {t('AssignTracker.Subtitle')}
            </span>
                </div>
                <div className="sub_header_action">
                </div>
            </div>
            <div className="psc_wrapper">
                <div className="psc_row">
                    <CalendarOutlined className="psc_icon"/>
                    <DatePicker
                        className="psc_input"
                        defaultValue={selectedDate}
                        value={selectedDate}
                        onChange={datepicker_handler}
                        format={dateFormat}
                        placeholder={t('RaceCalendar.Select_Date')}
                    />
                </div>
                <div className="psc_row">
                    <NodeIndexOutlined className="psc_icon"/>
                    <Select
                        className="psc_input select"
                        mode="Tags"
                        id="SiteH"
                        placeholder={t('RaceCalendar.Select_Site')}
                        style={{width: 500}}
                        onChange={venueHandler}
                        defaultValue={selectedVenue}
                        value={selectedVenue}
                        allowClear
                        showSearch>
                        {venueList.map((venue) => {
                            return (<Option
                                    key={venue.code}
                                    value={venue.code}>
                                        <span className="race_number">
                                            {`${t('monitoring.meetingShortcut')}${venue.meeting}`}
                                        </span>
                                        <span>{venue.label}</span>
                            </Option>);
                        })}
                    </Select>
                </div>
            </div>
            <div className="pdl_wrapper">
                {!isParameterSet ?
                    <span className="tracker_table_no_record">Select date and site</span> :
                    <TrackerTable/>}
            </div>
        </div>);
}
