import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import {Trans, useTranslation} from "react-i18next";

export const ERRORS = {
    GENERAL_FAILURE: 'GENERAL_FAILURE',
    BAD_FUSION_ID: 'BAD_FUSION_ID',
    BAD_TRACKER_ID: 'BAD_TRACKER_ID',
    TOO_MANY_TRACKERS: 'TOO_MANY_TRACKERS',
    NO_TRACKER: 'NO_TRACKER',
    TRACKER_NOT_ONCE: 'TRACKER_NOT_ONCE',
    MERGE_NOT_ONCE: 'MERGE_NOT_ONCE',
};

let ID = 1;

export function checkErrors(t, results) {
    if (results.errors.length > 0) {
        return {errors: [{
            key: 'ERR' + (ID++),
            code: ERRORS.GENERAL_FAILURE
        }], fusions: []};
    } else {
        const errors = [];
        const fusions = results.data
            // remove empty line
            .filter(line => line.length !== 0)
            // remove line with no data
            .filter(line => line.filter(c => c!=='').length !== 0)
            // remove title line
            .filter(line => line[0] !== t('TrackerFusion.fusionId'))
            // format
            .map(line => {
                const [fusionId, ...trackers] = line;
                return {
                    fusionId: parseInt(fusionId),
                    trackers: trackers
                                .filter(t => t!==undefined)
                                .filter(t => t!=='')
                                .map(t => parseInt(t)),
                    line,
                }
            });
        const fusionsByTrackers = {};
        const fusionsSet = {};
        fusions.forEach(item => {
            // check Fusion Id is number
            if (isNaN(item.fusionId)) {
                errors.push({
                    key: 'ERR' + (ID++),
                    code: ERRORS.BAD_FUSION_ID,
                    line: item.line,
                    value: item.line[0],
                })
            }
            // Check all merge is unique
            // line[0] not fusionId, to prevent 'NaN' instead of 'A'
            if (fusionsSet[item.line[0]]) {
                errors.push({
                    key: 'ERR' + (ID++),
                    code: ERRORS.MERGE_NOT_ONCE,
                    value: item.line[0],
                })
            }
            fusionsSet[item.line[0]] = true;
            // check no more than 5 trackers per fusion
            if (item.trackers.length > 5) {
                errors.push({
                    key: 'ERR' + (ID++),
                    code: ERRORS.TOO_MANY_TRACKERS,
                    line: item.line,
                    value: item.trackers.length,
                })
            }
            // check at least one tracker per fusion
            if (item.trackers.length === 0) {
                errors.push({
                    key: 'ERR' + (ID++),
                    code: ERRORS.NO_TRACKER,
                    line: item.line,
                })
            }
            // check each Tracker Id is number
            item.trackers.forEach((tracker, index) => {
                if (isNaN(tracker)) {
                    errors.push({
                        key: 'ERR' + (ID++),
                        code: ERRORS.BAD_TRACKER_ID,
                        line: item.line,
                        value: item.line[index+1],
                    })
                } else {
                    fusionsByTrackers[tracker] = fusionsByTrackers[tracker] || [];
                     // line[0] not fusionId, to prevent 'NaN' instead of 'A'
                    fusionsByTrackers[tracker].push(item.line[0]);
                }
            });
        })
        // check each tracker is only once.
        Object.keys(fusionsByTrackers)
            .map(tracker => ({tracker, fusions:fusionsByTrackers[tracker]}))
            .filter(item => item.fusions.length > 1)
            .forEach(item => {
                errors.push({
                    key: 'ERR' + (ID++),
                    code: ERRORS.TRACKER_NOT_ONCE,
                    value: item.tracker,
                    fusions: item.fusions,
                })
            })
        return {errors, fusions};
    }
}

export default function TrackerFusionImportErrors({errors}) {
    const {t} = useTranslation();
    if (errors.length === 0) {
        return (<></>);
    }
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        {/* <th>t(`TrackerFusion.import.code`)}</th> */}
                        <th>{t(`TrackerFusion.import.label`)}</th>
                        <th>{t(`TrackerFusion.import.values`)}</th>
                    </tr>
                </thead>
                <tbody>
                    {errors.map(error => (
                        <tr key={error.key}>
                            {/* <td>{error.code}</td> */}
                            <td style={{paddingRight: 10}}>
                                <span style={{paddingRight: 5}}>
                                    {t('TrackerFusion.error.import-'+error.code+'-label')}
                                </span>
                                <Tooltip placement="topLeft" title={t('TrackerFusion.error.import-'+error.code+'-details')}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </td>
                            <td style={{color: 'red'}}>
                                <Trans
                                    i18nKey={'TrackerFusion.error.import-'+error.code+'-values'}
                                    values={{
                                        value: error.value,
                                        line: error.line?.join(';'),
                                        fusions: error.fusions?.join(', '),
                                    }}
                                    components={{ bold: <strong /> }}
                                    />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
