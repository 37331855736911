import React, {useEffect, useMemo, useState} from "react";
import {GoogleMap, InfoWindow, LoadScript, Marker, Polyline} from "@react-google-maps/api";

// COMPONENTS
import Loader from "../components/loader"
import {useCurrentRaceTrackContext, usePositionRaceDataContext} from "../pages/privateMonitoring";
import {Button, Drawer, Slider, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseOutlined, UpOutlined} from "@ant-design/icons";
import { useLocalStorage } from "../hooks/useLocalStorage";

// MAP containerStyle 
const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
};

const default_map_options = {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 6.0 // value for LEFT_BOTTOM
    },
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 3,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
    disableDefaultUI: true
}

function MLSwitch({id, text, onChange, checked, checkedChildren=<CheckOutlined />, unCheckedChildren=<CloseOutlined />}) {
    const {t} = useTranslation();
    return (
        <div className="item">
            <label className="label" htmlFor={id}>{t(text)}</label>
            <Switch
                className="switch"
                id={id}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                checked={checked}
                onChange={onChange}>
            </Switch>
        </div>
    )
}

const DEFAULT_NB_OBJECT_ON_MAP = 500;
const POSSIBLES_NB_OBJECT_ON_MAP = [500, 750, 1000, 1250, 1500, 2000, 2500];

function MLNbObjectSlider({id, onChange, value}) {
    const {t} = useTranslation();

    return (
        <div className="item item-double">
            <label className="label" htmlFor={id}>{t("Nb tracks objects on Map")}</label>
            <Slider
                className="ml-slider"
                min={0}
                max={POSSIBLES_NB_OBJECT_ON_MAP.length-1}
                step={1}
                value={POSSIBLES_NB_OBJECT_ON_MAP.indexOf(value)}
                dots={true}
                onChange={ index => onChange(POSSIBLES_NB_OBJECT_ON_MAP[index]) }
                tooltip={{ formatter : index =>  `${POSSIBLES_NB_OBJECT_ON_MAP[index]} objects // ${POSSIBLES_NB_OBJECT_ON_MAP[index]/4} doors` }}
                />
        </div>
    )
}


const icon_size = 10;

function MLMarkPoint({visible, position, color, visibleLabel, label}) {
    return (
        <Marker
            visible={visible}
            position={position}
            icon={{
                url: `${process.env.PUBLIC_URL}/images/icons/${color}_dot.png`,
                scaledSize: new window.google.maps.Size(icon_size, icon_size)
            }}>
            { visibleLabel && label ?
                <InfoWindow
                    position={position}
                    clickable={true}>
                    <p>{label}</p>
                </InfoWindow>:''
            }
        </Marker>
    );
}

const INDEX_LATITUDE_CORDE_INTERIEURE = 1;
const INDEX_LONGITUDE_CORDE_INTERIEURE = 2;
const INDEX_LATITUDE_CORDE_A_X_METRE = 3;
const INDEX_LONGITUDE_CORDE_A_X_METRE = 4;
const INDEX_LATITUDE_CORDE_EXTERIEURE = 5;
const INDEX_LONGITUDE_CORDE_EXTERIEURE = 6;
const INDEX_NOM_PORTE = 7;

function isPorte(line) {
    const nomPorte = line[INDEX_NOM_PORTE]?.trim(); // "0" ou "PCDEP" ou "PCARR" ou "PCXXXX" ou ... (porte d'obstacle, etc.)
    if (!nomPorte) {
        // A priori pas de undefined ou null possible, mais sait on jamais ...
        return false;
    }
    const isPorteDep = nomPorte === 'PCDEP';
    const isPorteArr = nomPorte === 'PCARR';
    const isPorteDistance = (Number(nomPorte) !== 0 && parseInt(nomPorte.substr(2)) )
    return isPorteDep || isPorteArr || isPorteDistance;
}


export const MonitoringMap = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState();
    const raceTrack = useCurrentRaceTrackContext();
    const positionRaceData = usePositionRaceDataContext();
    const [mapCenter, setMapCenter] = useState();
    const [mapZoom, setMapZoom] = useState(17);
    const [mapOptions, setMapOptions] = useState(false);
    const [innerTrackMarkerList, setInnerTrackMarkerList] = useState([]);
    const [outerTrackMarkerList, setOuterTrackMarkerList] = useState([]);
    const [pipelineList, setPipelineList] = useState([]);
    const [computedPositionMarkerList, setComputedPositionMarkerList] = useState([]);
    const [rawPositionMarkerList, setRawPositionMarkerList] = useState([]);
    // Map Properties
    const [showInnerTrack, setShowInnerTrack] = useLocalStorage('monitoring-showInnerTrack', true);
    const [showTrackGate, setShowTrackGate] = useLocalStorage('monitoring-showTrackGate', true);
    const [showOuterTrack, setShowOuterTrack] = useLocalStorage('monitoring-showOuterTrack', true);
    const [showRawPosition, setShowRawPosition] = useLocalStorage('monitoring-showRawPosition', false);
    const [showComputedPosition, setShowComputedPosition] = useLocalStorage('monitoring-showComputedPosition', true);
    const [followRace, setFollowRace] = useLocalStorage('monitoring-followRace', true);
    const [mapType, setMapType] = useLocalStorage('monitoring-mapType', 'SATELLITE');
    const [fitBounds, setFitBounds] = useLocalStorage('monitoring-fitBounds', true);
    const [nbObjectOnMap, setNbObjectOnMap] = useLocalStorage('monitoring-nbObjectOnMap', DEFAULT_NB_OBJECT_ON_MAP);

    const [visible, setVisible] = useState(false);
    const [toolbarButton, setToolbarButton] = useState();

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    // onMapLoad ------------------------------------------------------
    const onMapLoad = (map) => {
        setMap(map);
        if (mapType === 'ROADMAP') {
            map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        } else {
            map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        }
    };

    const clearMap = () => {
        if (!window.google || !map) {
            return;
        }
        setComputedPositionMarkerList([]);
        setInnerTrackMarkerList([]);
        setOuterTrackMarkerList([]);
        setPipelineList([]);
        for (let i = 0; i < rawPositionMarkerList.length; i++) {
            rawPositionMarkerList[i].marker.setMap(null);
        }
        rawPositionMarkerList.splice(0, rawPositionMarkerList.length);
        setRawPositionMarkerList([]);
    }

    const getToolbarButton = () => {
        return <Button type="primary" className="map_markers_drawer_btn" onClick={showDrawer}><UpOutlined /></Button>;
    }

    const cleanMarkers = (markers) => {
        markers.forEach(function(marker){
            marker.marker.setMap(null);
        });
    }

    const setComputedPositionData = (computedRacePositionData) => {
        if (!window.google || !map) {
            return;
        }
        // undraw the race data when marker list is set but empty computed race data are then sent
        if (computedRacePositionData && computedRacePositionData.length === 0 && computedPositionMarkerList.length > 0) {
            cleanMarkers(computedPositionMarkerList);
            setComputedPositionMarkerList([]);
            return;
        }
        if (!computedRacePositionData || computedRacePositionData.length === 0 || !computedPositionMarkerList) {
            return;
        }
        let computedPositionMarkerListCopy = computedPositionMarkerList.slice();
        for (let c = 0; c < computedRacePositionData.length; c++) {
            let obj = computedRacePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = computedPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showComputedPosition && obj.status === 'DP');
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showComputedPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num/${obj.number}.png`,
                        zindex: 1
                    })
                    computedPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

            // map follow
            if (raceTrack && followRace) {
                adjustCenterAndBound(computedPositionMarkerListCopy, false);
            }
        }
        setComputedPositionMarkerList(computedPositionMarkerListCopy);
    };

    const setRawPositionData = (racePositionData) => {
        if (!window.google || !map) {
            return;
        }
        if (!racePositionData || racePositionData.length === 0 || !rawPositionMarkerList) {
            return;
        }
        let rawPositionMarkerListCopy = rawPositionMarkerList.slice();
        for (let c = 0; c < racePositionData.length; c++) {
            let obj = racePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = rawPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showRawPosition);
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showRawPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num_raw_hpv2/${obj.number}.png`,
                        zindex: 1
                    })
                    rawPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

        }
        setRawPositionMarkerList(rawPositionMarkerListCopy);
    };

    // getTrack ----------------------------------------------------
    // display track on map
    const setTrack = (trackContent, nbObjectOnMap) => {
        if (trackContent) {
            let innerArrayMark = [];
            let outerArrayMark = [];
            let pipelineList =[];
            const lines = trackContent
                .split(/\r?\n/)
                .map(line => line.split(';'))
                .filter(values => values.length !== 0)
                .filter(values => values.length !== 1);
            const {portes, autres} = lines
                .reduce(
                    (split, line) => (isPorte(line) ? split.portes.push(line) : split.autres.push(line)) && split,
                    {portes: [], autres: []}
                );
            
            // 1 Porte : 2 points, un trait, une pop-pup
            // 1 Autre : 2 points

            const NB_OBJETS_PAR_PORTE = 4;
            const nbObjetsDePorte = NB_OBJETS_PAR_PORTE * portes.length;
            const nbPorteASupprimer = Math.max(0 , nbObjetsDePorte - nbObjectOnMap) / NB_OBJETS_PAR_PORTE;
            const NB_OBJETS_PAR_AUTRE = 2;
            const nbObjetsDeAutre = NB_OBJETS_PAR_AUTRE * autres.length;
            const maxNbObjetsAutresOnMap = Math.max(0 , nbObjectOnMap - nbObjetsDePorte) / NB_OBJETS_PAR_AUTRE;
            const nbAutresASupprimer = Math.max(0, nbObjetsDeAutre - maxNbObjetsAutresOnMap )/ NB_OBJETS_PAR_AUTRE;


            console.log('-----------------------')
            console.log('NbPorte', portes.length)
            console.log('nbObjetsDePorte', nbObjetsDePorte, 4 * portes.length)
            console.log('NbAutre', autres.length)
            console.log('nbObjetsDeAutre', nbObjetsDeAutre, 2 * autres.length)
            console.log('-->')
            // console.log('nbPorteASupprimer', nbPorteASupprimer)
            // console.log('nbAutresASupprimer', nbAutresASupprimer)
            console.log('nbPorteAGarder', portes.length - nbPorteASupprimer)
            console.log('nbAutresAGarder', autres.length - nbAutresASupprimer)

            const coefPorte = nbPorteASupprimer / portes.length;
            const coefAutre = nbAutresASupprimer / autres.length;
            // console.log(`On doit supprimer ${coefPorte * 100}% portes.`);
            // console.log(`On doit supprimer ${coefAutre * 100}% autres.`);
            // console.log(`On doit garder ${(1 - coefPorte) * 100}% portes.`);
            // console.log(`On doit garder ${(1 - coefAutre) * 100}% autres.`);

            let nbASupprimer = 0
            const indexesPorteAGarder = [0, portes.length - 1];
            for(let index = 1; index < portes.length - 1; index++) {
                const coefActuel = (nbASupprimer + 1) / (nbASupprimer + indexesPorteAGarder.length)
                if (coefActuel < coefPorte) {
                    nbASupprimer++;
                } else {
                    indexesPorteAGarder.push(index);
                }
            }
            nbASupprimer = 0
            const indexesAutreAGarder = [];
            for(let index = 0; index < autres.length; index++) {
                const coefActuel = (nbASupprimer + 1) / (nbASupprimer + indexesAutreAGarder.length)
                if (coefActuel < coefAutre) {
                    nbASupprimer++;
                } else {
                    indexesAutreAGarder.push(index);
                }
            }
            console.log(`Au final, je garde ${indexesPorteAGarder.length}/${portes.length} portes.`);
            console.log(`Au final, je garde ${indexesAutreAGarder.length}/${autres.length} autres.`);

            portes
                .filter((_, index) => indexesPorteAGarder.includes(index))
                .forEach((values, index) => {
                    let positionInterieure = {
                        lat: parseFloat(values[INDEX_LATITUDE_CORDE_INTERIEURE]),
                        lng: parseFloat(values[INDEX_LONGITUDE_CORDE_INTERIEURE]),
                    };
                    let positionExterieure = {
                        lat: parseFloat(values[INDEX_LATITUDE_CORDE_EXTERIEURE]),
                        lng: parseFloat(values[INDEX_LONGITUDE_CORDE_EXTERIEURE]),
                    };
                    let label = values[INDEX_NOM_PORTE];
                    pipelineList.push(<Polyline key={`polyline_${index}`} path={[positionInterieure, positionExterieure]}/>);
                    innerArrayMark.push(<MLMarkPoint key={`marker_int_door_${index}`} visible={showInnerTrack} position={positionInterieure} color="green" visibleLabel={showTrackGate} label={label}/>);
                    outerArrayMark.push(<MLMarkPoint key={`marker_ext_door_${index}`} visible={showOuterTrack} position={positionExterieure} color="green"/>);
                });
            autres
                .filter((_, index) => indexesAutreAGarder.includes(index))
                .forEach((values, index) => {
                    let positionInterieure = {
                        lat: parseFloat(values[INDEX_LATITUDE_CORDE_INTERIEURE]),
                        lng: parseFloat(values[INDEX_LONGITUDE_CORDE_INTERIEURE]),
                    };
                    let positionExterieure = {
                        lat: parseFloat(values[INDEX_LATITUDE_CORDE_EXTERIEURE]),
                        lng: parseFloat(values[INDEX_LONGITUDE_CORDE_EXTERIEURE]),
                    };
                    innerArrayMark.push(<MLMarkPoint key={`marker_int_other_${index}`} visible={showInnerTrack} position={positionInterieure} color="blue"/>);
                    outerArrayMark.push(<MLMarkPoint key={`marker_ext_other_${index}`} visible={showOuterTrack} position={positionExterieure} color="red"/>);
                })

            setInnerTrackMarkerList(innerArrayMark);
            setOuterTrackMarkerList(outerArrayMark);
            setPipelineList(pipelineList);
            adjustCenterAndBound(innerArrayMark, true);
            map.setZoom(17);
        }
    };

    const adjustCenterAndBound = (data, useProps) => {
        if (raceTrack && followRace) {
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < data.length; i++) {
                if (useProps) {
                    bounds.extend(data[i].props.position)
                } else {
                    if (data[i].marker.visible) {
                        bounds.extend(data[i].marker.position)
                    }
                }
            }
            if (map) {
                map.setCenter(bounds.getCenter());
                if (fitBounds) {
                    map.fitBounds(bounds);
                }
            }
        }
    }

    const outerTrackHandler = React.useCallback(function callback(value) {
        setShowOuterTrack(value);
    }, [])

    const innerTrackHandler = React.useCallback(function callback(value) {
        setShowInnerTrack(value);
    }, [])

    const trackGateHandler = React.useCallback(function callback(value) {
        setShowTrackGate(value);
    }, [])

    const rawPositionHandler = React.useCallback(function callback(value) {
        setShowRawPosition(value);
    }, [])

    const computedPositionHandler = React.useCallback(function callback(value) {
        setShowComputedPosition(value);
    }, [])

    const followRaceHandler = React.useCallback(function callback(value) {
        setFollowRace(value);
    }, [])

    const fitBoundsHandler = React.useCallback(function callback(value) {
        setFitBounds(value);
    }, [])

    const mapTypeHandler = React.useCallback(function callback(value) {
        if (value) {// true is equals to satellite
            setMapType('SATELLITE');
        } else {
            setMapType('ROADMAP');
        }
    }, [])

    const nbObjectOnMapHandler = React.useCallback(function callback(value) {
        console.log('Change map complexity to', value);
        setNbObjectOnMap(value)
    }, [])

    // useEffect ------------------------------------------------------
    useEffect(() => {
        if (loading) {
            if (window.google) {
                setToolbarButton(getToolbarButton());
                setMapOptions(default_map_options);
                setLoading(false);
                setMapCenter(JSON.parse(localStorage.getItem('mapDefaultCenter')));
                setMapZoom(Number(localStorage.getItem('mapDefaultZoom')));
            }
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.google]);

    useEffect(() => {
        if (map && raceTrack) {
            setTrack(raceTrack, nbObjectOnMap);
        } else {
            clearMap();
            setInnerTrackMarkerList([]);
            setOuterTrackMarkerList([]);
            setPipelineList([]);
            setComputedPositionMarkerList([]);
            setRawPositionMarkerList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, raceTrack, showTrackGate, showOuterTrack, showInnerTrack, nbObjectOnMap]);

    useEffect(() => {
        if (positionRaceData.raw_position) {
            setRawPositionData(positionRaceData.raw_position);
        }
        if (positionRaceData.computed_position) {
            setComputedPositionData(positionRaceData.computed_position);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionRaceData]);

    useEffect(() => {
        if (map) {
            if (mapType === 'ROADMAP') {
                map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
            } else {
                map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapType]);

    const mapComponent = useMemo(() => {
        return <LoadScript
                   googleMapsApiKey="AIzaSyBRHVJET38Cx1CGYAkiErRdKh7gi2V4etM"
                   loadingElement={<Loader/>}>
                   {loading ?
                   <Loader/> :
                   <GoogleMap
                        id='pilotageMap'
                        mapContainerStyle={containerStyle}
                        zoom={mapZoom}
                        center={mapCenter}
                        onLoad={onMapLoad}
                        options={mapOptions}>

                            {props.children}
                            {innerTrackMarkerList}
                            {outerTrackMarkerList}
                            {pipelineList}
                            {toolbarButton}
                            <Drawer title={t('Map Control Options')} onClose={onClose} visible={visible}
                                    placement='bottom' height={'25%'}
                                    id={'map_control'}>
                                <div id={'map_control'}>
                                    <MLSwitch
                                        id="map_raw_position_switch"
                                        text="Display raw race positions"
                                        onChange={rawPositionHandler}
                                        checked={showRawPosition}/>
                                    <MLSwitch
                                        id="map_computed_position_switch"
                                        text="Display computed race positions"
                                        onChange={computedPositionHandler}
                                        checked={showComputedPosition}/>
                                    <MLSwitch
                                        id="map_track_gates_switch"
                                        text="Display intermediate gates"
                                        onChange={trackGateHandler}
                                        checked={showTrackGate}/>
                                    <MLSwitch
                                        id="map_inner_track_switch"
                                        text="Display inner track"
                                        onChange={innerTrackHandler}
                                        checked={showInnerTrack}/>
                                    <MLSwitch
                                        id="map_inner_track_switch"
                                        text="Display outer track"
                                        onChange={outerTrackHandler}
                                        checked={showOuterTrack}/>
                                    <MLSwitch
                                        id="fit_bounds_switch"
                                        text="Fit bounds when following race on track"
                                        onChange={fitBoundsHandler}
                                        checked={fitBounds}/>
                                    <MLSwitch
                                        id="follow_race_on_track_switch"
                                        text="Follow race on track"
                                        onChange={followRaceHandler}
                                        checked={followRace}/>
                                    <MLSwitch
                                        id="map_type_switch"
                                        text="Map type"
                                        checkedChildren={t('Satellite')}
                                        unCheckedChildren={t('Roadmap')}
                                        onChange={mapTypeHandler}
                                        checked={mapType === 'SATELLITE'}/>
                                    <MLNbObjectSlider
                                        id="nb_map_complexity_objects"
                                        onChange={nbObjectOnMapHandler}
                                        value={nbObjectOnMap}/>
                                </div>
                            </Drawer>
                    </GoogleMap>}
                </LoadScript>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, innerTrackMarkerList, outerTrackMarkerList, positionRaceData, visible])

    // render ---------------------------------------------------------
    return <> {mapComponent} </>
})

export default MonitoringMap;
