import axios from "axios";
import authHeader from "./auth-header";
const FileDownload = require('js-file-download');

const selectRace = async (uuid) => {
    return axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/select/${uuid}`, method: 'post',headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// list -------------------------------------------------------------
const list = async (data) => {
    let queryParam = '?date=' + data.date;
    if (data.venueCode) {
        queryParam += '&venueCode=' + data.venueCode;
    }
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/races' + queryParam, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const listAll = async (data) => {
    let queryParam = '?date=' + data.date;
    if (data.venueCode) {
        queryParam += '&venueCode=' + data.venueCode;
    }
    return axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/races' + queryParam, method: 'get', headers: authHeader()
    }).then(response => {
        if (response.status!==200) {
            console.log(response.status, response.statusText, response.data);
            return {venues: [], races: []};
        } else {
            return response.data;
        }
    }).catch(function (error) {
        console.log(error.response.data);
        return {venues: [], races: []};
    })
}

const getLatestRaceState = async (venueCode) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/latest?venueCode=${venueCode}`,
        method: 'get',
        headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getActivatedRace = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/activated`, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const get = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/${uuid}`, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// save -------------------------------------------------------------
const saveOne = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/races/',
        method: 'post',
        headers: authHeader(),
        data: data
    }).catch(function (error) {
        console.log(error.response.data);
        throw error;
    })
}

const saveFile = async (file) => {
    let formData = new FormData();
    formData.append('file', file);
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/races/all',
        method: 'post',
        headers: authHeader(),
        data: formData,
    }).catch(function (error) {
        console.log(error.response.data);
        throw error;
    })
}

// del --------------------------------------------------------------
const del = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/${uuid}`, method: 'delete', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getMecRaceData = async (uuid, isSectional) => {
    return axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/${uuid}/data/mec/${isSectional? 'sectional': 'full'}`,
        method: 'get', headers: authHeader(), responseType: 'blob', // Important
    })
        .then((response) => {
            const fileName = isSectional? `SectionalTrackingData_${uuid}.json`: `FullTrackingData_${uuid}.json`
            FileDownload(response.data,  fileName);
        })
        .catch(function (error) {
            console.log(error.response.data);
        })
}

const launchMec = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/mec/${uuid}`,
        method: 'post', headers: authHeader(), data: {}
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const launchReport = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/report/${uuid}`,
        method: 'post', headers: authHeader(), data: {}
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const manualChange = async (number, pc) => {
    return axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/races/manual/${number}/PC${pc}`, method: 'get', headers: authHeader()
    })
}


// default export ---------------------------------------------------
const race = {
    list,
    listAll,
    get,
    saveOne,
    saveFile,
    del,
    getActivatedRace,
    selectRace,
    getLatestRaceState,
    getMecRaceData,
    launchMec,
    launchReport,
    manualChange,
};

export default race;