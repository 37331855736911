import React, {useEffect, useState} from "react";
import {DatePicker, Select} from "antd";
import {CalendarOutlined, FlagOutlined, NodeIndexOutlined} from "@ant-design/icons";
import moment from "moment-timezone";
// INTERNAL
import race from "../services/race.service";
import venue from "../services/venue.service";
import {useTranslation} from "react-i18next";
import {useIsEngineActiveContext, useSelectedRaceContext} from "../pages/privateMonitoring";

const {Option} = Select;
const dateFormat = "YYYY-MM-DD";

export const MonitoringSearchCriteria = React.forwardRef(() => {
    const {t} = useTranslation();

    const [selectedDate, setSelectedDate] = useState();
    const [venueOptions, setVenueOptions] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState();
    const [raceOptions, setRaceOptions] = useState([]);
    const {selectedRace, setSelectedRace} = useSelectedRaceContext();
    const [selectedRaceTrack, setSelectedRaceTrack] = useState();
    const {isEngineActive} = useIsEngineActiveContext();
    const [searchCriteriaDisabled, setSearchCriteriaDisabled] = useState(false);

    // load data ------------------------------------------------------
    const loadRace = (raceUuid) => {
        race.get(raceUuid).then(race => {
            if (race && race.data) {
                const raceDate = moment(race.data.date);
                const raceVenue = race.data.track.venue.code;
                setSelectedDate(raceDate);
                loadVenueOptions(raceDate);
                loadRaceOptions(raceVenue, raceDate);
                setSelectedVenue(raceVenue);
                setSelectedRaceTrack(race.data.track.code);
                raceHandler(raceUuid);
            }
        });
    };

    const loadVenueOptions = (date) => {
        venue.list((date) ? date.format("YYYY-MM-DD") : undefined).then(result => {
            if (result && result.data) setVenueOptions(result.data);
        });
    };

    const loadRaceOptions = (venueCode, date) => {
        race.list({
            date: (date) ? date.format("YYYY-MM-DD") : selectedDate.format("YYYY-MM-DD"),
            venueCode: (venueCode) ? venueCode : selectedVenue,
        }).then(result => {
            if (result && result.data) setRaceOptions(result.data['races']);
        })
    };

    // handlers -------------------------------------------------------
    const dateHandler = (date) => {
        setSelectedRace(null);
        setSelectedRaceTrack(null);
        setSelectedDate(date);
        if (date) {
            loadVenueOptions(date);
        } else {
            setVenueOptions([])
        }
        setSelectedVenue(null);
        setRaceOptions([]);
    }

    const venueHandler = (venue) => {
        setSelectedVenue(venue);
        setRaceOptions([]);
        setSelectedRace(null);
        setSelectedRaceTrack(null);
        loadRaceOptions(venue);
    }

    const raceHandler = (raceUuid) => {
        setSelectedRace(raceUuid);
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setSearchCriteriaDisabled(isEngineActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEngineActive])

    // first load, init data
    useEffect(() => {
        // the page is reached to monitor a dedicated race
        if (selectedRace) {
            loadRace(selectedRace);
        } else {
            if (!selectedVenue) {
                setSelectedDate(moment());
                loadVenueOptions(moment());
                setRaceOptions([]);
                setSelectedVenue(null);
                setSelectedRace(null);
                setSelectedRaceTrack(null);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    // render ---------------------------------------------------------

    return (
        <div className="psc_wrapper">
            <div className="psc_row">
                <CalendarOutlined className="psc_icon"/>
                <DatePicker
                    className="psc_input"
                    placeholder="Date ..."
                    value={selectedDate}
                    onChange={dateHandler}
                    format={dateFormat}
                    disabled={searchCriteriaDisabled}
                />
            </div>
            <div className="psc_row">
                <NodeIndexOutlined className="psc_icon"/>
                <Select
                    className="psc_input select"
                    placeholder={t('monitoring.meeting')}
                    value={selectedVenue}
                    onChange={venueHandler}
                    allowClear
                    disabled={searchCriteriaDisabled}>
                    {venueOptions.map(venue => {
                        return <Option key={venue.code}
                                       value={venue.code}
                                       className="psc_race_option_label">
                            <span className="race_number">{`${t('monitoring.meetingShortcut')}${venue.meeting}`}</span>
                            <span>{venue.label}</span>
                        </Option>
                    })}
                </Select>
            </div>
            <div className="psc_row">
                <FlagOutlined className="psc_icon"/>
                <Select
                    className="psc_input select"
                    placeholder={t('monitoring.race')}
                    value={selectedRace}
                    onChange={raceHandler}
                    allowClear
                    disabled={searchCriteriaDisabled}>
                    {raceOptions.map(race => {
                        const raceDate = new Date(race.date);
                        return <Option key={race.uuid}
                                       value={race.uuid}
                                       className="psc_race_option_label">
                            <span className="race_number">{`${t('monitoring.raceShortcut')}${race.number}`}</span>
                            <span>{`${race.label}`}</span>
                            <span className={`race_time ${(raceDate <= new Date()) ? 'crossed' : ''}`}>
                                <span className="time">{raceDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</span>
                            </span>
                        </Option>
                    })}
                </Select>
            </div>
            <div className="psc_row">
                {selectedRaceTrack ?
                    <span>{`${t('Menu.RaceTrack')} : ${selectedRaceTrack}`}</span> : ''
                }
            </div>
        </div>
    )
})

export default MonitoringSearchCriteria;