import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, message, Select, Tabs, Modal} from 'antd';
import settings from "../services/settings.service";
import server from "../services/server.service";
import positionServer from "../services/position-server.service";

import './privateSettings.css';
import confirm from 'antd/lib/modal/confirm';

const {TabPane} = Tabs;
const {Option} = Select;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

function PanelHPv2Selection({hpv2List, updateHpv2Fields, formHpv2Selection}) {
    const {t} = useTranslation();

    const onFinish = (values) => {
        const positionServerUpdate = {
            uuid: values.hpv2_uuid,
            isHpv2ControlEnabled: values.is_hpv2_control_enabled,
            isSelected: true,
        };
        positionServer
            .update(positionServerUpdate)
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    message.success(t('common.save_success'));
                    window.location.reload();
                } else {
                    message.error(t('common.error'));
                }
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    const onChange = (uuid) => {
        if (uuid) {
            const selectedServer = hpv2List.find(server => server.uuid === uuid);
            if (selectedServer) {
                updateHpv2Fields(selectedServer);
            }
        }
    }

    const hpv2GetLabel = (item) => {
        return item.name+' ('+item.url+')';
    }

    return  <>
        <Form
            {...layout}
            form={formHpv2Selection}
            name="hpv2_selection_form"
            className="common_form"
            onFinish={onFinish}>

            <Form.Item name="hpv2_uuid" label={t('settings.hpv2_selection.hpv2_addr')} rules={[{required: true}]}>
                <Select onChange={onChange}>
                    {hpv2List.map(hpv2 => {
                        return <Option key={hpv2.uuid} value={hpv2.uuid}>
                            {hpv2GetLabel(hpv2)}
                        </Option>
                    })}
                </Select>
            </Form.Item>

            <Form.Item name="is_hpv2_control_enabled" label={t('settings.hpv2_selection.hpv2_control_enabled')} valuePropName="checked">
                <Checkbox/>
            </Form.Item>

            <Form.Item {...tailLayout} className="form_toolbar">
                <Button type="primary" htmlType="submit">
                    {t('common.save')}
                </Button>
            </Form.Item>

        </Form>
    </>;
}

function PanelHPv2Configuration({formHpv2Settings}) {
    const {t} = useTranslation();

    const [mccMncList, setMccMncList] = useState([]);

    useEffect(() => {
        positionServer.getAllMccMnc().then(mccMncList => {
            setMccMncList(mccMncList.data);
        })
    }, [])

    const onFinish = (values) => {
        const settingsServerUpdate ={
            mode: Number(values.hpv2_mode),
            height_sensor: Number(values.hpv2_tracker_height),
            channel: Number(values.hpv2_channel),
            mccMnc: Number(values.hpv2_mcc_mnc),
            networkTechnology: Number(values.hpv2_network_technology)};
        positionServer
            .updateHpv2Settings(settingsServerUpdate)
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    message.success(t('common.save_success'));
                } else {
                    message.error(t('common.error'));
                }
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    return <>
        <Form
            {...layout}
            form={formHpv2Settings}
            name="hpv2_settings_form"
            className="common_form"
            onFinish={onFinish}>
            <fieldset>
                <legend>{t('settings.hpv2_settings.general_legend')}</legend>
                <Form.Item name="hpv2_mode" label={t('settings.hpv2_settings.hpv2_mode_label')} rules={[{required: true}]}>
                    <Select value="hpv2_mode">
                        <Option key="outdoor" value="1">
                            Outdoor
                        </Option>
                        <Option key="indoor" value="2">
                            Indoor
                        </Option>
                        <Option key="outdoor-fast" value="3">
                            Outdoor-Fast
                        </Option>
                        <Option key="outdoor-sync-gps" value="4">
                            Outdoor-SyncGPS
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="hpv2_tracker_height" label={t('settings.hpv2_settings.hpv2_tracker_height_label')} rules={[{required: true}]}>
                    <Input placeholder="" type="number"/>
                </Form.Item>
                <Form.Item name="hpv2_channel" label={t('settings.hpv2_settings.hpv2_channel_label')} rules={[{required: true}]}>
                    <Select value="hpv2_channel">
                        <Option key="hpv2_channel_1" value="1">
                            1
                        </Option>
                        <Option key="hpv2_channel_2" value="2">
                            2
                        </Option>
                        <Option key="hpv2_channel_3" value="3">
                            3
                        </Option>
                        <Option key="hpv2_channel_5" value="5">
                            5
                        </Option>
                    </Select>
                </Form.Item>
            </fieldset>
            <fieldset>
                <legend>{t('settings.hpv2_settings.network_legend')}</legend>
                <Form.Item name="hpv2_mcc_mnc"
                        label={t('settings.hpv2_settings.hpv2_mcc_mnc_label')}
                        rules={[{required: true}]}
                        extra="Orange : 20801 / SFR : 20810 / Bouygues Tel : 20820 / Free : 20815">
                    <Select value="hpv2_mcc_mnc">
                        {mccMncList.map(mccMnc => {
                            return <Option key={`${mccMnc.mcc}${mccMnc.mnc}`} value={`${mccMnc.mcc}${mccMnc.mnc}`}>
                                {mccMnc.countryCode + ' ' + mccMnc.brand + ' '+ mccMnc.mcc+mccMnc.mnc}
                            </Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item name="hpv2_network_technology" label={t('settings.hpv2_settings.hpv2_network_technology_label')} rules={[{required: true}]}>
                    <Select value="hpv2_network_technology">
                        <Option key="hpv2_network_technology_3" value="3">
                            3G
                        </Option>
                        <Option key="hpv2_network_technology_4" value="4">
                            4G
                        </Option>
                    </Select>
                </Form.Item>
            </fieldset>
            <Form.Item {...tailLayout} className="form_toolbar">
                <Button type="primary" htmlType="submit">
                    {t('common.save')}
                </Button>
            </Form.Item>
        </Form>
    </>;
}

function PanelMapConfiguration({formMapSettings}) {
    const {t} = useTranslation();

    const onFinish = (values) => {
        const settingsUpdate ={
            map_default_center: values.map_default_center,
            debug_mode: values.debug_mode,
            map_default_zoom: values.map_default_zoom}
        settings
            .save(settingsUpdate)
            .then(async record => {
                let settings = JSON.parse(record.config.data);
                localStorage.setItem("mapDefaultCenter", settings.map_default_center);
                localStorage.setItem("mapDefaultZoom", settings.map_default_zoom);
                message.success(t('common.save_success'));
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    return <>
        <Form
            {...layout}
            form={formMapSettings}
            name="map_settings_form"
            className="common_form"
            onFinish={onFinish}>
            <Form.Item name="map_default_center" label={t('settings.map_settings.map_default_center')} rules={[{required: true}]}>
                <Input placeholder={`{"lat":46.80372236013024,"lng":2.6458026132760217}`}/>
            </Form.Item>

            <Form.Item name="map_default_zoom" label={t('settings.map_settings.map_default_zoom')} rules={[{required: true}]}>
                <Input placeholder="6"/>
            </Form.Item>
            <Form.Item {...tailLayout} className="form_toolbar">
                <Button type="primary" htmlType="submit">
                    {t('common.save')}
                </Button>
            </Form.Item>
        </Form>
    </>
}

function PanelServerManager({hpv2Selected}) {
    const {t} = useTranslation();

    const applications = [{
        code: 'racing',
        trad: 'racing',
        requireIp: false,
        requirePassword: false,
    },{
        code: 'hpv2',
        trad: 'hpv2',
        requireIp: true,
        requirePassword: true,
    }];

    function askToRestartApplication(app) {
        Modal.confirm({
            title: t(`settings.server_manager.${app}.restart_confirm_title`),
            content: t(`settings.server_manager.${app}.restart_confirm_content`),
            onOk: async () => {
                return Promise.resolve()
                    .then(() => server.restartApplication(app, hpv2Selected.url, password))
                    .then(() => { message.info(t(`settings.server_manager.${app}.restart_success`)) }) // Les {} sont important, car on attend pas la fin du message pour fermer la modale de confirmation
                    .catch(() => { message.error(t(`settings.server_manager.${app}.restart_error`)) });// Les {} sont important, car on attend pas la fin du message pour fermer la modale de confirmation
            },
            onCancel() {},
        });
    }

    let password = null;
    function changePassword(value) {
        password = value;
    }

    return <>
        <div className="server_manager">
            <fieldset className="server_manager_applications">
                <legend className="server_manager_title">{t('settings.server_manager.restart_title')}</legend>
                {applications.map(app =>
                    <div className="server_manager_application">
                        <div className="server_manager_label">
                            {t(`settings.server_manager.${app.trad}.restart_label`)}
                        </div>
                        <div className="server_manager_action">
                            <Button type="primary" htmlType="button" onClick={() => askToRestartApplication(app.code)}>
                                {t(`settings.server_manager.${app.trad}.restart_action`)}
                            </Button>
                        </div>
                        { app.requireIp ? 
                            <div className="server_manager_param">
                                <div className="server_manager_param_label">Adresse</div>
                                <div className="server_manager_param_value">{hpv2Selected.url}</div>
                            </div>
                            : null
                        }
                        { app.requirePassword ? 
                            <div className="server_manager_param">
                                <div className="server_manager_param_label">Password</div>
                                <div className="server_manager_param_value"><Input onChange={(event) => changePassword(event.target.value)}/></div>
                            </div>
                            : null
                        }
                        <div className="server_manager_description">
                            {t(`settings.server_manager.${app.trad}.restart_description`)}
                        </div>
                    </div>
                )}
            </fieldset>
        </div>
    </>;
}

function PanelEngineParameterManager({}) {
    const {t} = useTranslation();

    const [data, setData] = useState({});
    const [choices, setChoices] = useState([]);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        settings.getEngineParametersChoices()
            .then(choices => setChoices(choices))
        settings.getEngineParameters()
            .then(parameters => setData(parameters))
    }, [])

    useEffect(() => {
        settings.getEngineParametersChoices()
            .then(choices => setChoices(choices))
        settings.getEngineParameters()
            .then(parameters => setData(parameters))
    }, [reload])

    function format(data) {
        let response = Object.keys(data)
            .map(key => `     ${key}: ${JSON.stringify(data[key])}`)
            .join(',\n')
        return'{\n' + response + '\n}';
    }

    function install(choice) {
        confirm({
            title: t('settings.engine_parameters.popin_change_choice.title'),
            onOk: () => settings
                .setEngineParametersChoice(choice)
                .then(() => setReload(reload+1)),
            content: t('settings.engine_parameters.popin_change_choice.content'),
        });
    }

    return (
        <div className="engine_parameters_manager">
            <ul className="engine_parameters_choices">
                {choices.map(choice => (
                    <li key={choice} className="engine_parameters_choice">
                        <span className="engine_parameters_choice_label">
                            {choice}
                        </span>
                        <button className="engine_parameters_choice_button" onClick={() => install(choice)}>
                            {t('settings.engine_parameters.install')}
                            </button>
                    </li>
                ))}
            </ul>
            <hr />
            <div className="engine_parameters_preview">{format(data)}</div>
        </div>
    );
}

export default function PrivateSettings() {
    const {t} = useTranslation();
    const [formHpv2Selection] = Form.useForm();
    const [formHpv2Settings] = Form.useForm();
    const [formMapSettings] = Form.useForm();
    const [hpv2List, setHpv2List] = useState([]);
    const [user, setUser] = useState({});
    const [hpv2Selected, setHpv2Selected] = useState({});

    // loadHpv2 ------------------------------------------------------
    const loadHpv2 = async () => {
        positionServer.getAll().then(positionsServers => {
            setHpv2List(positionsServers.data);
            const currentSelectedServer = positionsServers.data.find(server => server.isSelected === true);
            if (currentSelectedServer) {
                updateHpv2Fields(currentSelectedServer);
                setHpv2Selected(currentSelectedServer);
            }
        });
    }

    const loadHpv2Settings = async () => {
        positionServer.getHpv2Settings().then(hpv2Settings => {
            updateHpv2SettingsFields(hpv2Settings.data);
        });
    }

    const updateHpv2Fields = (selectedServer) => {
        formHpv2Selection.setFieldsValue({hpv2_uuid: `${selectedServer.uuid}`});
        formHpv2Selection.setFieldsValue({is_hpv2_control_enabled: selectedServer.isHpv2ControlEnabled});
    }

    const updateHpv2SettingsFields = (hpv2Settings) => {
        formHpv2Settings.setFieldsValue({hpv2_mode: `${hpv2Settings.mode}`});
        formHpv2Settings.setFieldsValue({hpv2_tracker_height: hpv2Settings.height_sensor});
        formHpv2Settings.setFieldsValue({hpv2_channel: hpv2Settings.channel});
        formHpv2Settings.setFieldsValue({hpv2_mcc_mnc: `${hpv2Settings.mccMnc}`});
        formHpv2Settings.setFieldsValue({hpv2_network_technology: `${hpv2Settings.networkTechnology}`});
    }

    // load_data ------------------------------------------------------
    const loadData = async () => {
        settings.get().then(settings => {
            localStorage.setItem("mapDefaultCenter", settings.data[0].map_default_center);
            localStorage.setItem("mapDefaultZoom", settings.data[0].map_default_zoom);
            formMapSettings.setFieldsValue({map_default_center: settings.data[0].map_default_center});
            formMapSettings.setFieldsValue({map_default_zoom: settings.data[0].map_default_zoom});
            formMapSettings.setFieldsValue({debug_mode: settings.data[0].debug_mode});
        });
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
        loadHpv2();
        loadHpv2Settings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hpv2List) {
            loadData().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hpv2List])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper private-settings">
            <div className="header">
                <span className="title">{t('Menu.Settings')}</span>
            </div>
            <Tabs defaultActiveKey="2">
                { user && user.roles && user.roles.includes("ROLE_ADMIN")
                    ?
                        <TabPane tab={t('settings.hpv2_selection.title')} key="1">
                            <PanelHPv2Selection hpv2List={hpv2List} updateHpv2Fields={updateHpv2Fields} formHpv2Selection={formHpv2Selection} />
                        </TabPane>
                    :
                        null
                }
                <TabPane tab={t('settings.hpv2_settings.title') + ` (${hpv2Selected.name})`} key="2">
                    <PanelHPv2Configuration formHpv2Settings={formHpv2Settings} />
                </TabPane>
                <TabPane tab={t('settings.map_settings.title')} key="3">
                    <PanelMapConfiguration formMapSettings={formMapSettings}/>
                </TabPane>
                { user && user.roles && user.roles.includes("ROLE_ADMIN")
                    ?
                        <TabPane tab={t('settings.server_manager.title')} key="4">
                            <PanelServerManager hpv2Selected={hpv2Selected}/>
                        </TabPane>
                    :
                        null
                }
                { user && user.roles && user.roles.includes("ROLE_ADMIN")
                    ?
                        <TabPane tab={t('settings.engine_parameters.title')} key="5">
                            <PanelEngineParameterManager />
                        </TabPane>
                    :
                        null
                }
            </Tabs>
        </div>
    )

}
