import axios from "axios";
import authHeader from "./auth-header";

const list = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const listAll = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions', method: 'get', headers: authHeader()
    }).then(response => {
        if (response.status === 200) {
            return response.data
        } else {
            return Promise.reject();
        }
    }).catch(function (error) {
        console.log(error);
        return Promise.reject();
    })
}

const saveOne = async (data) => {
    return axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/',
        method: 'post',
        headers: authHeader(),
        data: {fusionId: data.fusionId, trackers: data.trackers},
    }).then(response => {
        if (response.status === 204) {
            return response.data
        } else {
            return Promise.reject();
        }
    }).catch(error => {
        if (error?.response?.data?.code === 'TRACKERS_USED_SEVERAL_MERGE') {
            return Promise.reject(error.response.data);
        } else if (error?.response?.data?.code === 'TRACKERS_USED_SEVERAL_IN_SAME_MERGE') {
            return Promise.reject(error.response.data);
        } else {
            console.log(error);
            return Promise.reject();
        }
    })
}

const saveAll = async (fusions, stopOnError) => {
    return fusions.reduce(
        (previous, fusion) => previous
            .then(response => saveOne(fusion)
                                .then(() => {
                                    response.success.push(fusion.fusionId);
                                    return Promise.resolve(response);
                                })
                                .catch(() => {
                                    response.errors.push(fusion.fusionId)
                                    return stopOnError
                                        ? Promise.reject(response)
                                        : Promise.resolve(response)
                                })),
        Promise.resolve({
            success: [],
            errors: [],
        })
    );
}

const updateOne = async (data) => {
    return axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/',
        method: 'put',
        headers: authHeader(),
        data: data
    }).then(response => {
        if (response.status === 204) {
            return response.data
        } else {
            return Promise.reject();
        }
    }).catch(error => {
        if (error?.response?.data?.code === 'TRACKERS_USED_SEVERAL_MERGE') {
            return Promise.reject(error.response.data);
        } else if (error?.response?.data?.code === 'TRACKERS_USED_SEVERAL_IN_SAME_MERGE') {
            return Promise.reject(error.response.data);
        } else {
            console.log(error);
            return Promise.reject();
        }
    })
}

const delOne = async (id) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/'+id, method: 'delete', headers: authHeader()
    }).then(response => {
        if (response.status === 204) {
            return response.data
        } else {
            return Promise.reject();
        }
    }).catch(function (error) {
        console.log(error.response.data);
        return Promise.reject();
    })
}

const delAll = async (ids, stopOnError) => {
    return ids.reduce(
        (previous, id) => previous
            .then(response => delOne(id)
                                .then(() => {
                                    response.success.push(id);
                                    return Promise.resolve(response);
                                })
                                .catch(() => {
                                    response.errors.push(id)
                                    return stopOnError
                                        ? Promise.reject(response)
                                        : Promise.resolve(response)
                                })),
        Promise.resolve({
            success: [],
            errors: [],
        })
    );
}

const setFusionState = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/state', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const fusion = {
    list,
    listAll,
    saveOne,
    saveAll,
    updateOne,
    delOne,
    delAll,
    setFusionState
};

export default fusion;