// version cliente de engine_broadcast. Envoi et réception des
// données du moteur via websocket

export class engine_broadcast {

    // constructor
    constructor() {
        this.addr = process.env.REACT_APP_ENGINE_BROADCAST_ADDR;
        this.port = (process.env.REACT_APP_ENGINE_BROADCAST_USE_PORT === 'true') ? process.env.REACT_APP_ENGINE_BROADCAST_PORT : null;
        this.retry = 10;
        this.ws = null;


        clearInterval(this.keep_alive_interval);
        this.keep_alive_interval = false;
    }

    set_callback(cmd_callback) {
        this.cmd_callback = cmd_callback;
    }

    // connect
    connect() {
        let self = this;
        let addr = this.addr + ((this.port) ? `:${this.port}` : ``);
        let url = new URL('', addr)
        if (url.protocol === 'https') {
            url.protocol = url.protocol.replace('https', 'wss');
        } else {
            url.protocol = url.protocol.replace('http', 'ws');
        }

        this.ws = new WebSocket(url.href);

        this.ws.onopen = function () {
            clearInterval(self.keep_alive_interval);
            self.keep_alive_interval = false;
            self.keep_alive_interval = setInterval(() => self.keep_alive(), 1000)
        };

        this.ws.onmessage = function (event) {
            let r = JSON.parse(event.data);
            self.cmd_callback(self, r);
        }

        this.ws.onerror = function () {
            self.ws.close();
        };

        this.ws.onclose = function () {
            self.cmd_callback(self, 'closed');
        };
    }

    // keep_alive
    keep_alive() {
        if (!this.ws || this.ws.readyState >= 2) {
            console.log('keep_alive connect', this.ws?.readyState);
            this.connect();
        } else {
            this.send_cmd('ping');
        }
    }

    // close
    close() {
        if (this.ws === null) {
            console.error("ws_engine socket not open !");
            return;
        }
        this.ws.close();
        clearInterval(this.keep_alive_interval);
        this.ws = null;
    };

    // send_cmd
    send_cmd(cmd, data) {
        if (this.ws === null) {
            console.error("ws_engine socket not open !");
            return;
        }
        let self = this;
        this.wait_connection(function () {
                self.ws.send(JSON.stringify({cmd: cmd, data: data}))
            },
            100);
    };

    // wait_connection
    wait_connection(callback, interval) {
        if (this.ws && this.ws.readyState === 1) {
            callback();
        } else {
            let that = this;
            setTimeout(function () {
                that.wait_connection(callback, interval);
            }, interval)
        }
    }

    // set lock timestamp until then map toolbar is locked
    map_toolbar_mutex_lock() {
        this.map_toolbar_mutex = Date.now() + 1000; // now + 1 second
    }

    map_toolbar_mutex_check() {
        if (!this.map_toolbar_mutex) {
            return true;
        }
        return Date.now() > this.map_toolbar_mutex;
    }

}