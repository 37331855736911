// LIBS
import React, {useEffect, useMemo, useState} from "react";
import {Avatar,Select, Table, Tooltip, Input} from "antd";
import {useTranslation} from "react-i18next";
import horseColor from "../classes/horseColor";
import competitor from "../services/competitor.service";
import fusion from "../services/fusion.service";
import {useSelectedRaceContext, useTrackerSupervisionDataContext} from "../pages/privateMonitoring";
import {STARTER_STATUS_LIST, NON_STARTER_STATUS_LIST} from "../enums/startStatus";
import event from "../services/event.service";
import race from "../services/race.service";
import { getMonitoringColumns } from "../services/monitoring-columns.service";

const {Option} = Select;

export const MonitoringDataList = React.forwardRef(({columnVersion}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [competitors, setCompetitors] = useState([]);
    const trackerSupervisionData = useTrackerSupervisionDataContext();
    const {selectedRace} = useSelectedRaceContext();
    const [fusOptions, setFusOptions] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [manual_number, setManualNumber] = useState(1);
    const [manual_lastpc, setLastPC] = useState("");
    const [manual_newpc, setNewPC] = useState(0);
    const [manual_showdialog, setShowDialog] = useState(false);
    const [sortedInfo, setSortedInfo] = useState({columnKey: '', order: ''});

    const handleTableChange: OnChange = (pagination, filters, sorter) =>
    {
        setSortedInfo(sorter && Array.isArray(sorter) && sorter.length > 0 ? sorter[sorter.length - 1] : sorter);
    };
    
    const manualChange = () =>
    {
        if (!manual_newpc) return;

        race.manualChange(manual_number, manual_newpc)
            .then(r => { setShowDialog(false); })
            .catch(err => { alert('Erreur : ' + err); });
    }
    const setManualNumberAndPC = (number) =>
    {
        setManualNumber(number);
        const c = competitors.find(n => n.number == number);
        setLastPC(c ? c.lastPC : "-");
    }

    const getFusionList = () => {
        setFusOptions([]);
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    setFusOptions(r.data);
                }
                getCompetitors();
            });
    }

    // getCompetitors ------------------------------------------------------
    const getCompetitors = () => {
        setCompetitors([]);
        if (selectedRace) {
            competitor.getListByRace(selectedRace).then(res => {
                if (res && res.data && res.data.length) {
                    setCompetitors(res.data);
                }
            })
        }
    };

    const cellStatusHandler = (v, updatedCompetitor) => {
        if (updatedCompetitor) {
            updatedCompetitor.status = v;
            const data = {
                type: 'COMPETITOR_STATUS_UPDATE',
                competitorNumber: updatedCompetitor.number,
                updatedCompetitorStatus : v,
                raceUuid: selectedRace}
            event.save(data).then();
        }
    }

    const cellFusionHandler = (v, updatedCompetitor) => {
        if (updatedCompetitor) {
            updatedCompetitor.fusionId = v;
            const data = {
                type: 'COMPETITOR_FUSION_UPDATE',
                competitorNumber: updatedCompetitor.number,
                updatedCompetitorFusion : v,
                raceUuid: selectedRace}
            event.save(data).then();
        }
    }

    const setTrackersDataByCompetitor = (trackersData) => {
        if (!trackersData || !trackersData.length) {
            return;
        }
        if (competitors) {
            let competitorsCopy = competitors.slice();
            for (let c = 0; c < trackersData.length; c++) {
                let obj = trackersData[c];
                if (!obj) continue;
                const competitor = competitorsCopy.find(competitor => competitor.fusionId === obj.fusionId);
                if (competitor) {
                    competitor.status = obj.status;
                    competitor.trackers = obj.trackers;
                    competitor.raceRank = obj.rank;
                    competitor.json = obj.engineStatus;
                    competitor.latency = obj.latency;
                    competitor.DpDp = Math.floor(obj.DpDp);
                    competitor.lastPC = obj.lastPC ? obj.lastPC : "";
                    // competitor.hr_value = obj.trackers
                    //     .filter(t => t.hr_value !== null)
                    //     .filter(t => t.hr_value !== '-')
                    //     .filter(t => t.hr_value !== 0)
                    //     .map(t => t.hr_value)
                    //     .pop();
                }
            }
            setCompetitors(competitorsCopy);
        }
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        getMonitoringColumns()
            .then(list => setSelectedColumns(list))
            .then(() => setLoading(false))
    }, [columnVersion]);

    // on race selection, load data from db
    useEffect(() => {
        if (selectedRace) {
            getFusionList()
        } else {
            setCompetitors([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    useEffect(() => {
        if (trackerSupervisionData) {
            setTrackersDataByCompetitor(trackerSupervisionData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackerSupervisionData]);

    // columns definition ---------------------------------------------
    // number, status, fusion, trackers, inCharge, network, nwtype, battery, lt, mode, latency, json, rank, hr, cps, dpdp
    const columns = [
        {
            code: 'number',
            title: t('monitoring.competitorNumber'),
            dataIndex: "number",
            className: 'sec-col-num',
            ellipsis: true,
            render: (value) => {
                let color = horseColor(value);
                return (
                    <Avatar
                        style={{
                            backgroundColor: `#${color.color}`,
                            color: `#${color.font}`,
                            fontSize: `14px`,
                            border: `1px solid #${color.border}`,
                            verticalAlign: 'middle'
                        }}
                        size={25}>{value}</Avatar>
                )
            },
        },
        {
            code: 'status',
            title: t('monitoring.status'),
            dataIndex: "status",
            ellipsis: true,
            render: (value, record) => {
                return (
                    <Select value={value} onChange={v => cellStatusHandler(v, record)}>
                        {
                            STARTER_STATUS_LIST.map(status => {
                                return <Option
                                    key={`${record.uuid}-${status}`}
                                    value={status}>
                                    <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                                </Option>
                            })
                        }
                        {
                            NON_STARTER_STATUS_LIST.map(status => {
                                return <Option
                                    key={`${record.uuid}-${status}`}
                                    value={status}>
                                    <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                                </Option>
                            })
                        }
                    </Select>
                )
            }
        },
        {
            code: 'fusion',
            title: <div className="th_fus">
                <span>{t('monitoring.fusion')}</span>
            </div>,
            dataIndex: "fusionId",
            ellipsis: true,
            render: (value, record) => {
                return (
                    <Select value={value} onChange={v => cellFusionHandler(v, record)}>
                        <Option key={`null_${record.uuid}`} value={`null`}>-</Option>
                        {fusOptions.map(opt => {
                            return <Option
                                key={`${opt.fusionId}`}
                                value={opt.fusionId}>
                                {opt.fusionId}
                            </Option>
                        })}
                    </Select>
                )
            }
        },
        {
            code: 'trackers',
            title: t('monitoring.trackers'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-id`}>{tracker.trackerId}</span>
                        })}
                    </div>
                } else {
                    return <div className="capt_cell"><span>{t('monitoring.empty')}</span></div>
                }
            }
        },
        {
            code: 'inCharge',
            title: t('monitoring.isInCharge'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-is-in-charge`}>{tracker.isInCharge}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'network',
            title: t('monitoring.networkProvider'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-network-provider`}>{tracker.networkProvider}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'nwtype',
            title: t('monitoring.networkType'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-network-type`}>{tracker.networkType}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'battery',
            title: t('monitoring.battery'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-bat`}>{tracker.battery}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'lt',
            title: t('monitoring.lt'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                                return <span className={`${tracker.status}`}
                                             key={`${tracker.trackerId}-${i}-lt`}>{tracker.lt}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'mode',
            title: t('monitoring.mode'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.mode}`}
                                             key={`${tracker.trackerId}-${i}-mode`}>{t(`monitoring.${tracker.mode}`)}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'latency',
            title: t('monitoring.latency'),
            dataIndex: "latency",
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"> <span>{value} ms</span></div>
            }
        },
        {
            code: 'json',
            title: t('monitoring.json'),
            dataIndex: "json",
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"><span className={`${value}`}>{t(`monitoring.${value}`)}</span></div>
            }
        },
        {
            code: 'rank',
            title: t('monitoring.rank'),
            dataIndex: "raceRank",
            key: "raceRank",
            sorter: {
                compare: (a, b) => a.raceRank - b.raceRank,
                multiple: 0
            },
            sortOrder: sortedInfo.columnKey === 'raceRank' ? sortedInfo.order : null,
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"><span>{value}</span></div>
            }
        },
        {
            code: 'hr',
            title: t('monitoring.hr'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.hr_value}`}
                                             key={`${tracker.trackerId}-${i}-hr_value`}>{tracker.hr_value}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'cps',
            title: t('monitoring.cps'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.cps_value}`}
                                             key={`${tracker.trackerId}-${i}-cps_value`}>{tracker.cps_value}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            code: 'dpdp',
            title: t('monitoring.dpdp'),
            dataIndex: "DpDp",
            key: "DpDp",
            ellipsis: true,
            width: '50px',
            sorter: {
                compare: (a, b) => a.DpDp - b.DpDp,
                multiple: 0
            },
            sortOrder: sortedInfo.columnKey === 'DpDp' ? sortedInfo.order : null,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"><span>{value}</span></div>
            }
        },
        {
            code: 'lastpc',
            title: t('monitoring.lastpc'),
            dataIndex: "lastPC",
            key: "lastPC",
            ellipsis: true,
            width: '60px',
            sorter: {
                compare: (a, b) =>
                {
                    if (!a.lastPC && !b.lastPC) return 0;
                    if (!a.lastPC) return 1;
                    if (!b.lastPC) return -1;
                    return a.lastPC < b.lastPC ? -1 : 1;
                },
                multiple: 0
            },
            sortOrder: sortedInfo.columnKey === 'lastPC' ? sortedInfo.order : null,
            render: (value, record) => {
                if (record.number === manual_number) setLastPC(value);
                return <div className="capt_cell">
                        <span className={`rankcont ${record.status} ${value ? "" : "white"}`} key={`${record.fusionId}-lastpc`} onClick={() => { setManualNumberAndPC(record.number); setShowDialog(true); }}>{value ? value : ""}</span>
                    </div>
            }
        },
    ].filter(column => selectedColumns.includes(column.code));

    const table = useMemo(() => {
        return <div className="pdl_wrapper">
            <div className={"manual_dlgcont " + (manual_showdialog ? "manual_show" : "manual_hide")} onClick={() => setShowDialog(false)}>
                <div className="manual_dlg" onClick={e => e.stopPropagation()}>
                <div className="manual_title">Repositionnement manuel</div>
                <div className="manual_close" id="manual_close" onClick={() => setShowDialog(false)}>X</div>
                <div className="manual_line">
                    <div className="manual_label">Compétiteur : </div>
                    <div className="manual_input">
                            <Select value={manual_number} size='small' onChange={v => setManualNumberAndPC(v)}>
                            {competitors.map(c => {
                                return <Option
                                    key={`${c.number}`}
                                    value={c.number}>
                                    {c.number}
                                </Option>
                            })}
                        </Select>
                    </div>
                </div>
                <div className="manual_line">
                    <div className="manual_label">Dernière PC : </div>
                    <div className="manual_input">{manual_lastpc}</div>
                </div>
                <div className="manual_line">
                    <div className="manual_label">Nouvelle PC : </div>
                    <div className="manual_input">
                        <Input type="text" size='small' onChange={(e) => setNewPC(Number(e.target.value))} placeholder="2400"/>
                    </div>
                </div>
                <div className="manual_send" onClick={() => manualChange()}>Envoyer</div>
            </div>
            </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={competitors}
                    loading={loading}
                    rowKey="uuid"
                    className="pilotage_list"
                    onChange={handleTableChange}
                    rowClassName={(record) => {
                        if (NON_STARTER_STATUS_LIST.includes(record.status)) {
                            return 'editable-row disabled';
                        }
                        return 'editable-row';
                    }}
                    pagination={false}
                />
            </div>
    }, [competitors, manual_showdialog, manual_newpc, manual_lastpc, selectedColumns, loading, columns, manualChange, manual_number, setManualNumberAndPC])

    return <> {table} </>
})

export default MonitoringDataList;