import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./services/auth.service";
import App from './App';
import './i18n';

ReactDOM.render(
  // <React.StrictMode>
    <ProvideAuth>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProvideAuth>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);
