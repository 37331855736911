import React, {useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom';
import {Button, Form, Input, message, Select, Switch} from 'antd';
import {FormItemPassword} from "../components/formItemPassword";
import user from "../services/user.service";
import role from "../services/role.service";
import {useTranslation} from "react-i18next";

const {Option} = Select;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function PrivateUserForm() {
    const {t} = useTranslation();

    const {uuid} = useParams();
    const [title, setTitle] = useState();
    const [mode, setMode] = useState();
    const [passwordChange, setPasswordChange] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [form] = Form.useForm();
    const history = useHistory();

    // onFinish -------------------------------------------------------
    const onFinish = (values) => {
        user
            .save(values)
            .then(() => {
                message.success(t('utilisateur.Enregistrement'));
                history.push('/users');
            })
            .catch(() => {
                message.error(t('utilisateur.Error_msg'));
            })
    }

    // load_data ------------------------------------------------------
    const load_data = () => {
        user.load(uuid).then(user => {
            for (let i in user.data.roles) {
                user.data.roles[i] = user.data.roles[i].uuid;
            }
            form.setFieldsValue(user.data);
            setTitle(t('utilisateur.Utilisateur#') + user.data.username);
        });

    }

    // load_role ------------------------------------------------------
    const load_role = () => {
        role.listAll().then(res => setRoleList(res.data));
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        load_role();
        if (uuid) {
            setMode('update');
            load_data();
        } else {
            setMode('add');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (mode === 'add') {
            setTitle(t('utilisateur.Nv_Utilisateur'));
            setPasswordChange(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">


            <div className="header">
                <div className="sub_header_title">
                    <span className="title">{title}</span>
                    <span className="subtitle">{t('utilisateur.SubtitleForm')}</span>
                </div>
            </div>

            <Form {...layout} form={form} name="user_form" className="common_form" onFinish={onFinish}>
                <Form.Item name="uuid" label="UUID" hidden={true}>
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item name="username" label={t('utilisateur.Nom_Form')} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item name="email" label={t('utilisateur.email')} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <FormItemPassword name="password" label={t('utilisateur.Nom_MP')}
                    label_change={t('utilisateur.ChangeP')}
                    change={passwordChange}
                    required={true}/>

                <Form.Item name="roles" label={t('utilisateur.Roles_Form')} rules={[{required: true}]}>
                    <Select allowClear mode="multiple">
                        {roleList.map(r => {
                            return <Option key={r.uuid} value={r.uuid}>{r.name}</Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="active" label={t('utilisateur.Active_Form')} valuePropName="checked">
                    <Switch checkedChildren="Enable" unCheckedChildren="Disable" defaultChecked/>
                </Form.Item>
                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="submit" onClick={() => history.push("/users")}>
                        {t('utilisateur.Return_Form')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('utilisateur.Save_Form')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

}