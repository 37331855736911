import React, {useEffect, useImperativeHandle, useState} from "react";
import useInterval from "react-useinterval";
import {Button, DatePicker, Modal, Select, Table, Tooltip} from "antd";
import {
    CalendarOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    NodeIndexOutlined,
    FileTextOutlined,
    CoffeeOutlined,
    FilePdfOutlined,
    FileSearchOutlined,
    LoadingOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
// INTERNAL
import RaceService from "../services/race.service";
import {useHistory} from "react-router-dom";
import Loader from "../components/loader"
import settings from "../services/settings.service";

const {confirm} = Modal;
const {Option} = Select;

const CALENDAR_LIST_REFRESH_TIME = 500;

export const RaceCalendarDataList = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [raceList, setRaceList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedVenue, setSelectedVenue] = useState();
    const [user, setUser] = useState({});
    const [mecActivated, setMecActivated] = useState(false);
    const dateFormat = "YYYY-MM-DD";
    const history = useHistory();

    const competitors = [
        {
            title: t("RaceCalendar.Competitor.Number"),
            key: "Number",
            align: "right",
            sorter: (a, b) => a.number - b.number,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Number"}>{record.number}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Name"),
            key: "Name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Name"}>{record.name}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Status"),
            key: "Status",
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Status"}>{t(`competitor.status.${record.status}`)}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Distance"),
            key: "Distance",
            align: "right",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Distance"}>{record.distance}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Driver"),
            key: "Driver",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Driver"}>{record.driver}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Trainer"),
            key: "Trainer",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Trainer"}>{record.trainer}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Owner"),
            key: "Owner",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Owner"}>{record.owner}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Draw"),
            key: "Draw",
            align: "right",
            render: (values, record) => {
                return (
                    record.draw !== 0 ?
                    <>
                        <p key={record.uuid + "_Draw"}>{record.draw}</p>
                    </>:''
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Rank"),
            key: "Rank",
            align: "right",
            sorter: (a, b) => a.rank - b.rank,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Rank"}>{record.rank}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.OfficialRank"),
            key: "OfficialRank",
            align: "right",
            sorter: (a, b) => a.officialRank - b.officialRank,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_OfficialRank"}>{record.officialRank}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.ArrivalTime"),
            key: "ArrivalTime",
            sorter: (a, b) => a.arrivalTime - b.arrivalTime,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_ArrivalTime"}>{record.arrivalTime}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.OfficialArrivalTime"),
            key: "OfficialArrivalTime",
            sorter: (a, b) => a.officialArrivalTime - b.officialArrivalTime,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_OfficialArrivalTime"}>{record.officialArrivalTime}</p>
                    </>
                );
            },
        },
    ]

    // columns definition ---------------------------------------------
    const columns = [
        {
            title: <Tooltip title={t("RaceCalendar.table.Date.long")}>{t("RaceCalendar.table.Date.short")}</Tooltip>,
            key: "Date",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Date"}>{new Date(record.date).toLocaleDateString()}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Venue.long")}>{t("RaceCalendar.table.Venue.short")}</Tooltip>,
            key: "Venue",
            render: (value, record) => {
                return (
                    <>
                        <Tooltip title={t("monitoring.meetingShortcut")+`${record.meeting}`}>
                            <p key={record.uuid + "_Venue"}>{record.venueLabel}</p>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.table.Number.short"),
            key: "Number",
            align: "right",
            sorter: (a, b) => a.number - b.number,
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Number"}>{record.number}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Start_Time.long")}>{t("RaceCalendar.table.Start_Time.short")}</Tooltip>,
            key: "Start_Time",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Start_Time"}>{new Date(record.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Label.long")}>{t("RaceCalendar.table.Label.short")}</Tooltip>,
            key: "Label",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Label"}>{record.label}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Code.long")}>{t("RaceCalendar.table.Code.short")}</Tooltip>,
            key: "Code",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Code"}>{record.trackCode}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Competitors_Number.long")}>{t("RaceCalendar.table.Competitors_Number.short")}</Tooltip>,
            key: "Competitors",
            align: "right",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Competitors_Number"}>{record.competitors.length}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Distance.long")}>{t("RaceCalendar.table.Distance.short")}</Tooltip>,
            key: "Distance",
            align: "right",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Distance"}>{record.distance}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.Type.long")}>{t("RaceCalendar.table.Type.short")}</Tooltip>,
            key: "Type",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Race_Type"}>{record.type}</p>
                    </>
                );
            },
        },
        {
            title: <Tooltip title={t("RaceCalendar.table.col_action.long")}>{t("RaceCalendar.table.col_action.short")}</Tooltip>,
            dataIndex: "action",
            key: "action",
            className: "col_action",
            render: (value, race) => {
                return (
                    <div style={{whiteSpace: 'nowrap'}}>
                        <Tooltip title={t("common.del")}>
                            <Button
                                onClick={(e) => remove(e, race.uuid)}
                                className="ant-btn-circle"
                                icon={<DeleteOutlined/>}
                            />
                        </Tooltip>
                        {user.roles && user.roles.includes("ROLE_ADMIN") && <>
                        <Tooltip title={t("RaceCalendar.getData")}>
                            <Button
                                onClick={(e, ) => getMecRaceData(e, race.uuid, false)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus !== 'DONE'}
                                icon={race.mecStatus === 'FAILED' ? <CloseCircleOutlined /> :<FileTextOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.getSectionalData")}>
                            <Button
                                onClick={(e) => getMecRaceData(e, race.uuid, true)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus !== 'DONE'}
                                icon={race.mecStatus === 'FAILED' ? <CloseCircleOutlined /> :<FileSearchOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.launchMec")}>
                            <Button
                                onClick={(e) => launchMec(e, race.uuid)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus === 'IN_PROGRESS'}
                                icon={selectMecButtonState(race)}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.launchReport")}>
                            <Button
                                onClick={(e) => launchReport(e, race.uuid)}
                                className="ant-btn-circle"
                                disabled={race.reportStatus === 'IN_PROGRESS' || race.mecStatus !== 'DONE'}
                                icon={selectReportButtonState(race)}
                            />
                        </Tooltip>
                        <Tooltip title={
                            <Trans
                                i18nKey={'RaceCalendar.process-status'}
                                values={{
                                    MEC: race.mecStatus,
                                    Report: race.reportStatus,
                                }}
                                components={{ bold: <strong />, br: <br /> }}
                            />}>
                            <InfoCircleOutlined />
                        </Tooltip>
                        </>
                        }
                    </div>
                );
            },
        },
        Table.EXPAND_COLUMN
    ];

    const selectMecButtonState = (race) => {
        if (race.mecStatus === 'IN_PROGRESS') {
            return <LoadingOutlined/>
        } else {
            return <CoffeeOutlined/>
        }
    }

    const selectReportButtonState = (race) => {
        if (race.reportStatus === 'IN_PROGRESS') {
            return <LoadingOutlined/>
        } else if (mecActivated && race.mecStatus !== 'DONE') {
            return <CloseCircleOutlined />;
        } else {
            return <FilePdfOutlined/>
        }
    }

    // load_data ------------------------------------------------------

    const loadListFromServer = async () => {
        if (selectedDate) {
            return RaceService.list({
                date: (selectedDate) ? selectedDate.format("YYYY-MM-DD") : undefined,
                venueCode: (selectedVenue) ? selectedVenue : undefined
            }).then((r) => {
                if (r && r.data) {
                    setSiteList(r.data.venues);
                    setRaceList(r.data.races);
                    setSelectedVenue(selectedVenue);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            setSiteList([]);
            setRaceList([]);
            setSelectedVenue(null);
            return Promise.resolve();
        }
    };

    const updateListFromServer = () => {
        if (!updating) {
            // On prend le ticket d'update (pour pas qu'il y en ait plusieurs en même temps)
            setUpdating(true);
            RaceService.listAll({
                date: (selectedDate) ? selectedDate.format("YYYY-MM-DD") : undefined,
                venueCode: (selectedVenue) ? selectedVenue : undefined
            }).then((data) => {
                setSiteList(data.venues);
                setRaceList(data.races);
            }).finally(() => {
                // Quoi qu'il arrive, on doit lacher le ticket de refresh.
                setUpdating(false);
            });
        }
    }

    // handlers -------------------------------------------------------

    function datepickerHandler(date) {
        setSelectedDate(date);
        setSelectedVenue(null);
        setSiteList([]);
    }

    const venueHandler = (venue) => {
        setSelectedVenue(venue);
    }

    // methods --------------------------------------------------------

    // ADD
    const add = () => {
        history.push("/race/");
    };

    const getMecRaceData = async (e, uuid, isSectional) => {
        e.preventDefault();
        e.stopPropagation();
        await RaceService.getMecRaceData(uuid, isSectional);
    }

    const launchMec = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        return confirm({
            title: t("RaceCalendar.LaunchMECInformation.title"),
            content: <Trans
                i18nKey={'RaceCalendar.LaunchMECInformation.content'}
                components={{ bold: <strong />, br: <br /> }}
                />,
            okCancel: false,
            onOk: async () => {
                return RaceService.launchMec(uuid)
                    .then(() => updateListFromServer());
            },
        });
    }

    const launchReport = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        return confirm({
            title: t("RaceCalendar.LaunchReportInformation.title"),
            content: <Trans
                i18nKey={'RaceCalendar.LaunchReportInformation.content'}
                components={{ bold: <strong />, br: <br /> }}
                />,
            okCancel: false,
            onOk: async () => {
                return RaceService.launchReport(uuid)
                    .then(() => updateListFromServer());
            },
        });
    }

    // DEL
    const remove = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: t("RaceCalendar.DeleteConfirmTitle"),
            content: t("RaceCalendar.DeleteConfirmContent"),
            onOk: async () => {
                return Promise.resolve()
                    .then(() => RaceService.del(uuid))
                    .then(() => updateListFromServer());
            },
            onCancel() {
            },
        });
    };

    // useImperativeHandle --------------------------------------------
    useImperativeHandle(ref, () => ({

        add_race_ext() {
            add();
        }

    }))

    useInterval( () => {
        updateListFromServer();
    }, CALENDAR_LIST_REFRESH_TIME);


    // useEffect ------------------------------------------------------

    // on date selection, load data from db
    useEffect(() => {
        setLoading(true);
        loadListFromServer().then(() => {
            setLoading(false);
            setUser(JSON.parse(localStorage.getItem('user')));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedVenue]);

    useEffect(() => {
        settings.getMecActivatedSetting().then((response) => {
            setMecActivated(response.data.value)
        })
    }, []);
    
    // render ---------------------------------------------------------
    return (
        <>
            <div className="psc_wrapper">
                <div className="psc_row">
                    <CalendarOutlined className="psc_icon"/>
                    <DatePicker
                        className="psc_input"
                        defaultValue={selectedDate}
                        value={selectedDate}
                        onChange={datepickerHandler}
                        format={dateFormat}
                        placeholder={t('RaceCalendar.Select_Date')}
                    />
                </div>
                <div className="psc_row">
                    <NodeIndexOutlined className="psc_icon"/>
                    <Select
                        className="psc_input select"
                        mode="Tags"
                        id="SiteH"
                        placeholder={t('RaceCalendar.Select_Site')}
                        style={{width: 500}}
                        onChange={venueHandler}
                        defaultValue={selectedVenue}
                        value={selectedVenue}
                        allowClear
                        showSearch>
                        {
                            siteList.map((venue) => {
                                return (
                                    <Option
                                        key={venue.code}
                                        value={venue ? venue.code : ""}>
                                        <span
                                            className="race_number">{`${t('monitoring.meetingShortcut')}${venue.meeting}`}</span>
                                        <span>{venue.label}</span>
                                    </Option>
                                );
                            })}
                    </Select>
                </div>
            </div>
            <div className="pdl_wrapper">
                {loading ? <Loader/> : <div className="data_list programme">
                    <Table
                        bordered
                        columns={columns}
                        dataSource={raceList}
                        loading={loading}
                        rowKey="uuid"
                        className="common_list race_list"
                        rowClassName={(record) => {
                            if (record.unknownTrack) return "unknown_track";
                        }}
                        pagination={{pageSize: 20}}
                        expandable={{
                            expandedRowRender: record =>
                                <Table
                                    dataSource={record.competitors}
                                    columns={competitors}
                                    rowKey="uuid"
                                    className="competitor_list"
                                    pagination={false}
                                />
                        }}
                    />
                </div>}
            </div>
        </>
    );
});

export default RaceCalendarDataList;
