// LIBS
import React, {useEffect, useState} from "react";
import useInterval from "react-useinterval";
import {Button, Switch} from "antd";
import {CheckOutlined, CloseOutlined, FlagOutlined, ReloadOutlined, ThunderboltOutlined} from "@ant-design/icons";
import Countdown, {zeroPad} from 'react-countdown';
import {useTranslation} from "react-i18next";
import event from "../services/event.service";
import {useHpv2EngineContext, useSelectedRaceContext, useIsEngineActiveContext} from "../pages/privateMonitoring";
import positionServer from "../services/position-server.service";
import race from "../services/race.service";

const GET_HPV2_NETWORK_INFO_FREQUENCY = 10000;

// TODO GBE : Cette liste devrait être dans un endroit commun à FRONT et BACK
const EVENT_TYPES = {
    RACE_ACTIVATE: 'RACE_ACTIVATE',
    RACE_RESET: 'RACE_RESET',
    RACE_START: 'RACE_START',
    RACE_START_CONFIRM: 'RACE_START_CONFIRM',
    RACE_INFO: 'RACE_INFO',
    RACE_RESTART: 'RACE_RESTART',
    RACE_END_PHYSICAL: 'RACE_END_PHYSICAL',
    RACE_END_OFFICIAL: 'RACE_END_OFFICIAL',
    RACE_INFO_UPDATE: 'RACE_INFO_UPDATE',
    COMPETITOR_STATUS_UPDATE: 'COMPETITOR_STATUS_UPDATE',
    COMPETITOR_FUSION_UPDATE: 'COMPETITOR_FUSION_UPDATE',
    FORCE_NETWORK: 'FORCE_NETWORK',
};

export const MonitoringMapToolbar = React.forwardRef(() => {
    const {t} = useTranslation();
    const {hpv2Engine} = useHpv2EngineContext();
    const {selectedRace} = useSelectedRaceContext();
    const {isEngineActive} = useIsEngineActiveContext();
    // eslint-disable-next-line
    const [nextStartTime, setNextStartTime] = useState();
    const [lastCommand, setLastCommand] = useState();
    const [activateEnable, setActivateEnable] = useState(false);
    const [resetEnable, setResetEnable] = useState(false);
    const [startEnable, setStartEnable] = useState(false);
    const [restartEnable, setRestartEnable] = useState(false);
    const [confirmStartEnable, setConfirmStartEnable] = useState(false);
    const [endEnable, setEndEnable] = useState(false);
    const [currentSelectedNetwork, setCurrentSelectedNetwork] = useState();
    const [currentSelectedNetworkTechnology, setCurrentSelectedNetworkTechnology] = useState();
    const [isForcedNetworkEnabled, setIsForcedNetworkEnabled] = useState(false);

    // load data ------------------------------------------------------
    const loadSelectedRaceDate = () => {
        race.get(selectedRace).then(race => {
            if (race && race.data) {
                setNextStartTime(race.data.date);
            }
        });
    };

    const getCurrentHpv2Server = () => {
        // retrieve the current selected server in db to get network info
        positionServer.getSelected(true).then(hpv2Server => {
            if (hpv2Server && hpv2Server.data) {
                setCurrentSelectedNetwork(hpv2Server.data.networkInfo.mcc_mnc);
                setCurrentSelectedNetworkTechnology(hpv2Server.data.networkInfo.network_technology);
                setIsForcedNetworkEnabled(hpv2Server.data.networkInfo.is_forced_network_enabled);
            }
        });
    }

    // CountDownToRace ----------------------------------------------
    const CountDownToRace = () => {
        return (
            <Countdown
                date={nextStartTime}
                renderer={({hours, minutes, seconds, completed}) => {
                    if (completed) {
                        return t('monitoring.race_started');
                    } else {
                        return t('monitoring.next_start')+ ' ' + zeroPad(hours)+':'+zeroPad(minutes)+':'+zeroPad(seconds)

                    }
                }}
            />
        )
    }

    const sendEvent = (eventType, code, timestamp) => {
        const data = { "type": eventType, "raceUuid": selectedRace}
        if (code) {
            data.code = code;
        }
        if (timestamp) {
            data.timestamp = timestamp;
        }
        event.save(data).then();
    }

    const activate = () => {
        if (activateEnable) {
            sendEvent(EVENT_TYPES.RACE_ACTIVATE, 'B1');
            setLastCommand(t('monitoring.btn_engine_start'));
            setActivateEnable(false);
        }
    };

    const reset = () => {
        if (resetEnable) {
            sendEvent(EVENT_TYPES.RACE_RESET);
            setLastCommand(t('monitoring.btn_engine_reset'));
        }
    };

    const restart = () => {
        if (restartEnable) {
            sendEvent(EVENT_TYPES.RACE_RESTART, 'B2');
            setLastCommand(t('monitoring.btn_race_restart'));
        }
    };

    const start = () => {
        if (startEnable) {
            sendEvent(EVENT_TYPES.RACE_START, 'B3', Date.now());
            setLastCommand(t('monitoring.btn_race_start'));
        }
    };

    const confirmStart = () => {
        if (confirmStartEnable) {
            sendEvent(EVENT_TYPES.RACE_START_CONFIRM, 'B4');
            setLastCommand(t('monitoring.btn_confirm_race_start'));
        }
    };

    const end = () => {
        sendEvent(EVENT_TYPES.RACE_END_PHYSICAL);
        setLastCommand(t('monitoring.btn_race_end'));
    };

    const forceNetwork = (value) => {
        sendEvent(EVENT_TYPES.FORCE_NETWORK, value?'force':'unforce');
        setIsForcedNetworkEnabled(value);
    };

    useInterval( () => {
        getCurrentHpv2Server();
    },GET_HPV2_NETWORK_INFO_FREQUENCY);

    useEffect(() => {
        if (selectedRace) {
            if (!isEngineActive) {
                setActivateEnable(true);
            }
            loadSelectedRaceDate();
        } else {
            setActivateEnable(false);
            setNextStartTime(null);
        }
        getCurrentHpv2Server();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    useEffect(() => {
        if (selectedRace) {
            setActivateEnable(!isEngineActive);
        }
        setResetEnable(isEngineActive);
        setRestartEnable(isEngineActive);
        setStartEnable(isEngineActive);
        setConfirmStartEnable(isEngineActive);
        setEndEnable(isEngineActive);
        // if (nextStartTime) {
        //     let next = new Date().setHours(nextStartTime.split(':')[0], nextStartTime.split(':')[1])
        //     // set_countdown_next_start_time(next);
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEngineActive]);

    // render ---------------------------------------------------------
    return (
        <div className="map_pilotage_toolbar">
            <div className="map_pilotage_toolbar_header">
                {nextStartTime?
                <span className="next_start">
                  <CountDownToRace/>
                </span>
                :
                <span className="next_start_hidden">next_start</span>
                }
            </div>
            <div className="map_pilotage_toolbar_button_wrapper">
                {hpv2Engine ? (
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!activateEnable)? 'disable':''}`}
                        onClick={() => {activate(false);}}
                        shape="circle"
                        icon={<ThunderboltOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_engine_stop')}</span>
                </div>
                ) : (
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!activateEnable)? 'disable':''}`}
                        onClick={() => {activate(true);}}
                        shape="circle"
                        icon={<ThunderboltOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_engine_start')}</span>
                </div>
                )}

                {/* RACE_RESET */}
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!resetEnable)? 'disable':''}`}
                        onClick={() => {reset()}}
                        shape="circle"
                        icon={<ReloadOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_engine_reset')}</span>
                </div>

                {/* RACE_RESTART */}
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!restartEnable)? 'disable':''}`}
                        onClick={() => {restart();}}
                        shape="circle"
                        icon={<ReloadOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_race_restart')}</span>
                </div>

                {/* RACE_START */}
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!startEnable)? 'disable':''}`}
                        onClick={() => {start()}}
                        shape="circle"
                        icon={<FlagOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_race_start')}</span>
                </div>

                {/* RACE_END */}
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!confirmStartEnable)? 'disable':''}`}
                        onClick={() => {confirmStart()}}
                        shape="circle"
                        icon={<CheckOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_confirm_race_start')}</span>
                </div>

                {/* RACE_END */}
                <div className="tlb-btn-wrapper">
                    <Button
                        className={`tlb-btn ${(!endEnable)? 'disable':''}`}
                        onClick={() => {end()}}
                        shape="circle"
                        icon={<CheckOutlined/>}>
                    </Button>
                    <span>{t('monitoring.btn_race_end')}</span>
                </div>
            </div>

            <div className="map_pilotage_toolbar_footer">
                <span className="next_start">{t('monitoring.last_command')} : <span>{lastCommand}</span></span>
                {currentSelectedNetwork && selectedRace?
                <span id="force-network-switch-container">
                    <label htmlFor={'force-network-switch'}>{`${t('monitoring.force_network')} ${currentSelectedNetwork} : ${currentSelectedNetworkTechnology}G    `}</label>
                    <Switch
                        id="force-network-switch"
                        checked={isForcedNetworkEnabled}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(v) => { forceNetwork(v) }}>
                    </Switch>
                </span>:''
                }
            </div>
        </div>
    )
})

export default MonitoringMapToolbar;